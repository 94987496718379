import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getOrdersRecepit, SetLoading } from "../../store/actions/orderAction";
// import "../../assets/css/style.css";
import "./print.css";
import { v4 as uuidv4 } from "uuid";

const BillPrint = () => {
  const dispatch = useDispatch();
  const orderReference = new URLSearchParams(useLocation().search).get("orderReference");
  const buid = new URLSearchParams(useLocation().search).get("buid");
  // const orders = useSelector((state) => state.order.orderPaymentInfo);
  const loadingStatus = useSelector((state) => state.order.loading);
  const store = useSelector((state) => state.table.business_Info);
  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "j",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];
  // let [items, setItems] = useState([]);
  // let [total, setTotal] = useState(0);
  let [texes, setTexes] = useState({});

  let [data, setData] = useState({});
  let currentdate = new Date();

  useEffect(() => {
    if (orderReference && buid) {
      let data1 = {
        blId: buid,
        orderRef: orderReference
      };
      dispatch(getOrdersRecepit(data1)).then((resp) => {
        dispatch(SetLoading(false));
        if (resp?.success) {
          setData(resp?.data);
          let texData = {};
          resp?.data?.salesLines?.map((item) => {
            if (!texData[item.taxRatePercentage]) {
              let obj = {
                rate: Number(item.taxRatePercentage),
                taxAmount: Number(item.taxAmount),
                totalNetAmountWithoutTax: Number(item.totalNetAmountWithoutTax),
                total: Number(item.menuListPrice),
                number: Object.keys(texData).length
              };
              texData[item.taxRatePercentage] = obj;
            } else {
              texData[item.taxRatePercentage].taxAmount =
                texData[item.taxRatePercentage].taxAmount + Number(item.taxAmount);
              texData[item.taxRatePercentage].totalNetAmountWithoutTax =
                texData[item.taxRatePercentage].totalNetAmountWithoutTax +
                Number(item.totalNetAmountWithoutTax);
              texData[item.taxRatePercentage].total =
                texData[item.taxRatePercentage].total + Number(item.menuListPrice);
            }
          });
          setTexes(texData);
        }
      });
    }
  }, [orderReference, buid]);
  useEffect(() => {
    dispatch(SetLoading(true));
  }, []);
  // useEffect(() => {
  //   if (orders?.salesEntries?.length) {
  //     setItems([...orders?.salesEntries]);
  //     setTotal(orders?.paymentAmount);
  //   }
  // }, [orders]);
  console.log(texes);

  // const arrayTex = {
  //   "TSE-Transaktion": "122329",
  //   "TSE-Signatur-Nr.": "244997",
  //   "TSE-ErsteBestellung": "2022-09-13T10:21:03Z",
  //   "TSE-Start": "2022-09-13T11:27:44Z",
  //   "TSE-Stop": "2022-09-13T11:27:44Z",
  //   "TSE-Signatur":
  //     "/9Kx/LTSwMaH9cfQxtHLQooWqWxIU3kSsC6BDOuZRE/UjTiOUARCVGlThfJOOXPi5OVuvkvd+JnF2fKCeHnZAQ==",
  //   "TSE-PublicKey":
  //     "BJqrMSOMnmMaGllk+zwL8Yh2WK7aeOatOg2vlw6450Ma6ZtLhAn+nK1oqZtJ94N+3bassqKX+Y5vaR6ytojjEf8=",
  //   "TSE-Seriennummer": " 9a2499029a7f9e388ba44d40470101e3e243c13f1d7e43e019f896cd3adcd0d4",
  //   "Kasse-Seriennummer": "725197"
  // };

  return (
    <>
      {loadingStatus ? (
        <div className=" min-vh-100 w-100">
          <span
            className="spinner-border spinner-border-lg text-danger text-center position-absolute top-50 start-50"
            role="status"
            aria-hidden="true"></span>
        </div>
      ) : (
        <Row className="main_body_div">
          <Col lg="6" md="6" sm="6" xs="10" className="ps-3 ms-5 ">
            <div className="">
              <div>
                <div className="d-flex justify-content-center align-items-center position-relative">
                  <img  src={store.business_logo} style={{ height: "60px" }} alt="icon" />
                  <div className="position-absolute print-btn">
                    {/* <Button
                      variant="info"
                      size="md"
                      className="bg-primery"
                      onClick={() => window.print()}
                    >
                      Print
                    </Button> */}
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-3 flex-column font-line-height ">
                  <div className="d-flex justify-content-center align-items-center mt-1">
                    <div>*** COPY ***</div>
                  </div>
                  <div className="text-center w-100">{data?.businessName}</div>

                  <div className="d-flex justify-content-center align-items-center mt-3 flex-column ">
                    <div>Hammer Straße 17 </div>
                    <div>40212 Düsseldorf</div>
                  </div>

                  <div className="mt-3 w-100">
                    <div className="text-center">0211-65857204</div>
                    {data?.payments?.length == 1 && (
                      <div className="">
                        {data?.payments[0]?.consumer?.firstName +
                          " " +
                          data?.payments[0]?.consumer?.lastName}
                      </div>
                    )}
                    <div className=" d-flex justify-content-between w-100">
                      {data?.deviceName}
                      {/* /257730-307/200184-onl */} {data?.accountFiscId}
                    </div>
                    <div className=" d-flex w-100">
                      <div>Quittung </div>
                      <div className=""> {data?.receiptId}</div>
                      <div className="ps-2">
                        {currentdate.getDate() +
                          "." +
                          (currentdate.getMonth() + 1) +
                          "." +
                          currentdate.getFullYear() +
                          "," +
                          currentdate.getHours() +
                          ":" +
                          currentdate.getMinutes() +
                          ":" +
                          currentdate.getSeconds()}
                      </div>
                    </div>
                  </div>
                  <div className="dash-border w-100 mb-1"></div>
                  <div>
                    <div className="text-center">{data?.tableName}</div>
                  </div>
                  {data?.salesLines?.map((item) => {
                    return (
                      <div key={uuidv4()}>
                        {item.totalNetAmountWithTax !== "0.00" && (
                          <div className="d-flex justify-content-between w-100">
                            <div>{parseInt(item?.quantity)}</div>
                            <div> {item?.name}</div>
                            <div>
                              <span>{item?.menuListPrice}</span>
                              <span className="ps-3">
                                {item?.totalNetAmountWithTax}
                                {Object.values(texes)?.length > 1 &&
                                  ": " + alphabets[texes[item.taxRatePercentage].number]}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div className="mt-3 single-border w-100"></div>
                  <div className="d-flex justify-content-between w-100 mt-1">
                    <div> Summe</div>
                    <div>
                      €{" "}
                      {data?.status === "parital_paid"
                        ? data?.currentAmount
                        : data?.payments?.length &&
                          data?.payments?.reduce(function (acc, obj) {
                            return acc + Number(obj.netAmountWithTax);
                          }, 0)}
                    </div>
                  </div>
                  <div className="ps-2 pt-2 w-100 ">
                    {data?.payments?.length &&
                      data?.payments?.map((item, index) => {
                        return (
                          <div key={uuidv4()}>
                            <div className="d-flex justify-content-between pt-2" key={uuidv4()}>
                              {data?.payments?.length === 1 ? (
                                <div className="ps-3 ">EC</div>
                              ) : (
                                <div className=" ">Zahlung # {index + 1}</div>
                              )}
                              <div className="ps-2">
                                {
                                  // Number(item?.tip) &&
                                  <div>
                                    <span>€ </span>
                                    {(Number(item?.netAmountWithTax) + Number(item?.tip)).toFixed(
                                      2
                                    )}
                                  </div>
                                }
                              </div>
                            </div>
                            <div className="mt-1">
                              {" "}
                              {item?.consumer &&
                                item?.consumer?.firstName + " " + item?.consumer?.lastName}{" "}
                            </div>
                            <div className="ps-3 w-100 ">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <span>|</span> Trinkgeld
                                </div>
                                <div>€ {item?.tip}</div>
                              </div>
                            </div>
                            <div className="ps-3 w-100 ">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <span>|</span> Bezahlter Betrag
                                </div>
                                <div>€ {item?.netAmountWithTax}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {Object.values(texes)?.map((item) => {
                      return (
                        <div className="w-100 mt-4" key={uuidv4()}>
                          <span>
                            {" "}
                            {Object.values(texes)?.length > 1 && alphabets[item.number] + ": "}
                          </span>
                          <span className="">MwSt.</span>
                          <span className="ps-3">{item.rate}%</span>
                          <span className="ps-3"> auf </span>
                          <span className="ps-3">{item.totalNetAmountWithoutTax.toFixed(2)}:</span>
                          <span className="ps-3">
                            € {item.taxAmount.toFixed(2)} ({item.total})
                          </span>
                        </div>
                      );
                    })}
                    {/* <div className="w-100 text-center mt-3">
                      MwSt.-Nummer:DE333073351
                    </div> */}
                    {/* <div className="w-100 text-center">133/5805/3058</div> */}
                    <div className="w-100 text-center mt-3">Besten Dank für Ihren Besuch!</div>
                    {/* <div className="w-100 text-center">
                      Lightspeed (K) 4.1.53.5521
                    </div> */}
                    {/* <div className="single-border mt-3 w-100"></div> */}
                    {/* <div className="w-100 text-center mt-2">Abholungscode</div>
                    <div className="text-center">1</div>
                    <div className="text-center">Lieferzeit</div> */}
                    {/* <div className="text-center">
                      <span>26.10.22,</span>{" "}
                      <span className="ps-2"> 11:11</span>
                    </div> */}
                    <div className="single-border w-100 mt-3"> </div>
                    <div className="text-center">TSE Informationen</div>
                    {/* <div className="d-flex justify-content-between w-100">
                      <div>TSE-Transaktion:</div>
                      <div> 123634</div>
                    </div>
                    <div className="d-flex justify-content-between w-100">
                      <div>TSE-Signatur-Nr.: </div>
                      <div> 247650</div>
                    </div>
                    <div className="d-flex justify-content-between w-100">
                      <div>TSE-ErsteBestellung: </div>
                      <div> 2022-10-26T09:05:57Z</div>
                    </div> */}
                    <div className="d-flex justify-content-between w-100">
                      <div>TSE-Start: </div>
                      <div> {data?.timeOfOpening}</div>
                    </div>
                    <div className="d-flex justify-content-between w-100">
                      <div>TSE-Stop:</div>
                      <div>{data?.timeOfCloseAndPaid}</div>
                    </div>
                    {/* <div>
                      <div>
                        TSE-Signatur:
                        <span className="lineBreak ps-5">
                          hMAXWopyoG93JXJMK1PGB49gQ5eOKHH0CycfdfdgTOhgHN8j55wV8JEE2daxhJlZFdGbbpgqLSTEUDlHWrRneg==
                        </span>
                      </div>
                    </div> */}

                    {/* <div>
                      TSE-PublicKey:
                      <span className="lineBreak ps-5">
                        BO75uk1dPy6c5iVCkdSRfJDVQA5mmU+5ygD6CbUVmql1hykKgNIATHoM7CTUUedKueNqX+kHrRLmVgKqVNEdBCU=
                      </span>
                    </div> */}
                    {/* <div>
                      TSE-Seriennummer:
                      <span className="lineBreak ps-">
                        a9182e173f65fd64cd36fc351785a20b2714e17f2f00d3ad0ad79b4601242c47
                      </span>
                    </div> */}
                    <div className="d-flex justify-content-between w-100 pb-5">
                      <div>Kasse-Seriennummer: </div>
                      <div> {data?.deviceId}</div>
                    </div>
                  </div>

                  {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <p style={{ marginLeft: "10px" }}>
                          {item?.consumer &&
                            item?.consumer?.firstName +
                              " " +
                              item?.consumer?.lastName}{" "}
                        </p>
                        <p>€ {item?.amountPaid}</p>
                      </div>
                    </>
                  );
                })
              : data?.payments?.length &&
                data?.payments?.map((item, index) => {
                  return (
                    <>
                      <p style={{ marginTop: "5px" }}>
                        Zahlung # {index + 1}
                      
                      </p>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <p style={{ marginLeft: "10px" }}>
                          {item?.consumer &&
                            item?.consumer?.firstName +
                              " " +
                              item?.consumer?.lastName}{" "}
                        </p>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>
                            <p>{"Amount" + " "}</p>
                            <p>€ {item?.netAmountWithTax}</p>
                          </div>
                          <div>
                            <p></p>
                            <p> +</p>
                          </div>

                          <div
                            style={{
                              paddingLeft: "2gitpx",
                            }}
                          >
                            <p>{" " + "tip"}</p>
                            <p>{item?.tip}</p>
                          </div>
                          <div>
                            <p>Total</p>
                            <p>
                              =
                              {(
                                Number(item?.netAmountWithTax) +
                                Number(item?.tip)
                              ).toFixed(2)}
                            </p>
                          </div>
                        </div>

                       
                      </div>
                    </>
                  );
                })} */}

                  {/* <p style={{ marginTop: "10px" }}>
          MwSt. 7% auf 12.10: € 0.85 (12.95)
        </p> */}
                  {/* <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {" "}
          MwSt.-Nummer:DE333073360
        </p> */}
                  {/* <p
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {" "}
          133/5805/3069
        </p> */}
                  {/* <p
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {" "}
          Besten Dank für Ihren Besuch!
        </p> */}
                  {/* <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            borderBottom: ".5px dashed black",
          }}
        >
          Lightspeed (K) 4.1.49.4651
        </p> */}
                  {/* <div>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "2px",
            }}
          >
            TSE Informatione
          </p>

            {/* <p
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                borderBottom: ".5px dashed black",
              }}
            >
              Lightspeed (K) 4.1.49.4651
            </p> */}

                  {/* <div>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "2px",
                }}
              >
                TSE Informatione
              </p> */}
                  {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p>TSE-Start :</p>
                <p>{data?.timeOfOpening}</p>
              </div>
         
        </div>  */}
                  {/* </div>

              <div> */}
                </div>
              </div>
            </div>
          </Col>
          <Col lg="" md="4" sm="3" xs="12"></Col>
        </Row>
      )}
    </>
  );
};

export default BillPrint;
