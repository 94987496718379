import React from "react";
import { useSelector } from "react-redux";
import ModalInterface from "../../common/ModalInterface";

const BillNoItem = ({ billModal, setBillModal }) => {
  const page_theme = useSelector((state) => state.table?.business_Info?.page_layout?.instore?.menu);
  const text_modals = useSelector((state) => state?.table?.modalText);
  const uupsPopData = text_modals?.filter((updated) => "uups".includes(updated?.modal_type))[0];
  return (
    <>
      <ModalInterface
        display={billModal}
        size="md"
        style={{ position: "relative" }}
        handleClose={() => {
          setBillModal(false);
        }}
        title={<></>}
        center={true}
        content={
          <>
            <div
              className="d-flex flex-column justify-content-center "
              style={{ marginTop: "-14px" }}>
              <div className="d-flex justify-content-end">
                <button
                  className="d-flex justify-content-center align-items-center tex-center "
                  style={{
                    border: "none",
                    backgroundColor: page_theme?.primary_modal_cross_btn_bg_color,
                    borderRadius: "50px",
                    width: "30px",
                    height: "30px",
                    color: "white",
                    fontSize: "30px"
                  }}
                  onClick={() => {
                    setBillModal(false);
                  }}>
                  <i className="fa fa-close" style={{ fontSize: "16px" }}></i>
                </button>
              </div>
              {/* <div style={{ paddingRight: "40px", textAlign: "center" }}>
                <img
                  src="./img/tomato.png"
                  style={{ width: "200px", height: "100px" }}
                  alt="icon"></img>
              </div> */}
              <div
                className="justify-content-center d-flex"
                style={{
                  color: page_theme?.primary_uups_header_text_color,
                  fontWeight: "500",
                  fontSize: "50px",
                  fontFamily: "Source Sans Pro-Bold"
                }}>
                {uupsPopData?.heading_text || "UUPS"}
              </div>
              <div
                className="text-center pb-2 py-3"
                style={{
                  fontWeight: "500",
                  fontSize: "30px",
                  lineHeight: "36px",
                  fontFamily: "Source Sans Pro-SemiBold"
                }}>
                {uupsPopData?.primary_text || ""}
              </div>
              {uupsPopData?.secondary_text && (
                <div
                  className="text-center pb-3 "
                  style={{
                    fontWeight: "500",
                    fontSize: "20px",
                    lineHeight: "36px",
                    fontFamily: "Source Sans Pro-Light"
                  }}>
                  {uupsPopData?.secondary_text || ""}
                </div>
              )}
              <div className="text-center">
                <button
                  style={{
                    border: "none",
                    backgroundColor: page_theme?.primary_uups_btn_bg_color,
                    borderRadius: "50px",
                    width: "120px"
                  }}
                  onClick={() => {
                    setBillModal(false);
                  }}>
                  <i className="fa fa-check" style={{ color: "white", fontSize: "40px" }}></i>
                </button>
              </div>
            </div>
          </>
        }
        closeEvent={() => {}}
      />
    </>
  );
};

export default BillNoItem;
