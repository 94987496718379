import React, { useEffect, useState } from "react";
import Hero from "../components/home/Hero";
import ModalInterface from "../common/ModalInterface";
import { FoodMenu } from "../components/home/Menus";
import { useDispatch, useSelector } from "react-redux";
import { getspecials } from "../store/actions/specialAction";
import { loadVeganItems, menuAction, SetSearchResults } from "../store/actions/menuAction";
import { getBestSeller } from "../store/actions/bestsellerAction";
import StationTimings from "./StationTimings";

const Home = ({ setIsWelcome, searchSectionRef }) => {
  //Check if user has joined or created a table otherwise redirect to welcome
  const page_theme = useSelector(
    (state) => state.table?.business_Info?.page_layout?.instore?.footer
  );

  // const businessLogo = useSelector((state) => state.table?.business_Info?.business_logo);
  const store = useSelector((state) => state.table.business_Info);

  const subHeaderTheme = useSelector(
    (state) => state.table?.business_Info?.page_layout?.instore?.menu
  );

  const dispatch = useDispatch();
  const tableInfo = useSelector((state) => state.table);
  const loadingSpecials = useSelector((state) => state.special.loading);
  const loading = useSelector((state) => state.menu.loading);
  const busId = useSelector((state) => state?.table?.orderInfo?.businessLocationId);
  const data = useSelector((state) => state.menu.menu);
  const veganItems = useSelector((state) => state?.menu?.veganItems);
  const bestSellerData = useSelector((state) => state?.bestSeller?.bestSeller);
  const specialsItem = useSelector((state) => state?.special);
  const categoryIs = useSelector((state) => state.table.business_Info);

  useEffect(() => {
    dispatch(SetSearchResults([]));
    if (
      String(busId) !== "null" &&
      busId?.length !== 0 &&
      String(busId) !== "undefined" &&
      busId !== ""
    ) {
      loadSpecial();
      loadMenu();
    }
  }, []);

  async function loadMenu() {
    if (bestSellerData.length <= 0 && categoryIs.best_seller === true) {
      dispatch(getBestSeller(busId));
    }
    if (veganItems.length <= 0 && categoryIs.vegan === true) {
      dispatch(loadVeganItems(busId));
    }
    if (data.length <= 0) {
      dispatch(menuAction(busId));
    }
  }

  async function loadSpecial() {
    if (specialsItem?.special.length <= 0 && categoryIs.special === true) {
      dispatch(getspecials(busId));
    }
  }
  setIsWelcome(window.location.pathname);
  const [liveModal, setLiveModal] = useState(false);
  const checkTableInfo = () => {
    if (
      !tableInfo?.orderInfo?.businessLocationId ||
      !tableInfo?.business_Info?.business_name ||
      !tableInfo.table
    ) {
      return window.location.replace("/");
    }
  };

  useEffect(() => {
    checkTableInfo();
  }, []);
  return (
    <div style={{ backgroundColor: categoryIs?.body_bgcolor, paddingTop: "53px" }}>
      <div
        className="logo"
        style={{
          backgroundColor: subHeaderTheme?.primary_menu_subheader_bgcolor
        }}>
        <div className="mainLogo  my-3">
          <img
            src={store.business_logo}
            alt="main_logo"
            width="85%"
            style={{ maxWidth: "280px", verticalAlign: "middle" }}
          />
        </div>

        {/* <button
          className="liveBtn"
          data-bs-toggle="modal"
          data-bs-target="#liveModal"
          onClick={() => {
            setLiveModal(true);
          }}
        >
          <i className="fa-solid fa-clock"></i>
        </button> */}
      </div>
      <Hero specials={null} />
      <FoodMenu
        searchSectionRef={searchSectionRef}
        data={data}
        loadingSpecials={loadingSpecials}
        loading={loading}
        veganItems={veganItems}
        bestSellerData={bestSellerData}
        specialsItem={specialsItem}
      />
      <div className="my-5">
        <div
          className="bg-info mt-5 text-center"
          style={{
            padding: "4rem 1rem 3rem",
            backgroundColor: subHeaderTheme?.primary_menu_subheader_bgcolor,
            color: subHeaderTheme?.primary_menu_subheader_bgcolor
          }}>
          <img
            src={page_theme?.right_icon_url}
            style={{
              width: "195px",
              height: "55px",
              verticalAlign: "middle",
              padding: "0 0 1rem 0"
            }}
            // width={150}
            height={50}
            alt="Boname_logo"
          />
          <p
            // Duis autem vel eum iriure dolor in hendrerit in vulputate
            className="text-white mb-4 py-1 text-center w-100 pt-3"
            style={{ fontSize: "13px", textAlign: "justify" }}>
            <a
              className={`fs-${page_theme?.font_size_primary_text_title} font-${page_theme?.primary_text_font_family}`}
              href=" https://operate-app.com/privacy_policy/"
              target="_blank"
              style={{
                textDecoration: "none",
                color: page_theme?.primary_text_color
                // fontSize: "20px",
              }}>
              {" "}
              Datenschutzerkärung
            </a>
            <a
              className={`fs-${page_theme?.font_size_primary_text_title} font-${page_theme?.primary_text_font_family}`}
              href=" https://operate-app.com/impressum/"
              target="_blank"
              style={{
                textDecoration: "none",
                color: page_theme?.primary_text_color,
                // fontSize: "20px",
                paddingLeft: "30px"
              }}>
              {" "}
              Impressum
            </a>
          </p>
        </div>
      </div>
      <ModalInterface
        display={liveModal}
        title={""}
        center
        handleClose={() => {
          setLiveModal(false);
        }}
        setDisplay={setLiveModal}
        content={<StationTimings setLiveModal={setLiveModal} busId={busId} />}
        closeEvent={() => {
          setLiveModal(false);
        }}
      />
    </div>
  );
};

export default Home;
