import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link as ScrollLink } from "react-scroll";
import { handleSetActive } from "../../helper/scrollActive";
import { compareDesc, parse } from "date-fns";
import axios from "axios";
import { absoluteUrl } from "../../common/Constants";
import { useSelector } from "react-redux";
import { useQuery } from '@tanstack/react-query';

function compareDate(firstDate, secondDate) {
  if (compareDesc(new Date(firstDate), new Date(secondDate)) === -1) return false
  else return true
}

async function getAllGroups(blId) {
  try {
    const allgroups = await axios.get(absoluteUrl(`/group-logo/${blId}`));
    return allgroups?.data?.data.filter(function filterVisibleGroups(group) {
      const startTime = parse(group.startTime, "HH:mm", new Date());
      const endTime = parse(group.endTime, "HH:mm", new Date());
      if (typeof group.visible === 'undefined' || group.visible === null) {
        return true;
      }
      if (!group.active && group.visible) return true;
      if (group.active && group.visible && compareDate(new Date(), endTime) && compareDate(startTime, new Date()))
        return true;
      return false;
    });
    // return allgroups.data.data;
  } catch (error) {
    console.error("ERROR_FETCHING_GROUPS", error);
  }
}

const VapianoNavBar = ({ loading, groups, categoryIs, _scrollWidth }) => {

  const busId = useSelector((state) => state?.table?.orderInfo?.businessLocationId);
  const { data: allGroups } = useQuery({
    queryKey: ['allgroups', busId],
    queryFn: () => getAllGroups(busId)
  });

  return (
    <>
      <Navbar
        sticky="top"
        className="my-2 navheader"
        style={{ backgroundColor: "#f7f3ee", top: "53px", zIndex: "900" }}>
        <Nav
          className="text-center align-items-center ml-sm-3 ml-0 flex-row overflow-auto"
          id="navbar-menu"
          style={{
            maxHeight: "100px",
            whiteSpace: "nowrap",
            scrollBehavior: "smooth"
          }}
        >
          {!loading && groups ? (
            groups?.map((category, i) => {

              if (!category) { // If groupName is empty, return nothing
                return null;
              }

              if (category === "Special" && categoryIs.special === false) {
                return null;
              } else if (category === "VEGAN" && categoryIs.vegan === false) {
                return null;
              } else if (category === "Best Seller" && categoryIs.best_seller === false) {
                return null;
              } else if (allGroups?.find((group) => group.group == category && group.visible == false)) {
                return;
              } else if (allGroups?.every(obj => obj.group !== category && category !== "Special" && category !== "VEGAN" && category !== "Best Seller")) {
                return;
              } else {
                return (
                  <div key={`group${i}`}>
                    <ScrollLink
                      className="px-3 nav-link nav-link-menu"
                      to={`${i}`} // Use the index as the unique identifier
                      activeClass="active-scroll-spy"
                      spy={true}
                      offset={-250}
                      id={`category${i}`}
                      data-id={i}
                      onSetActive={(e) => handleSetActive(e, _scrollWidth, category)}
                    >
                      <h5 style={{ pointerEvents: "none", position: "relative" }}>
                        <b className="navitem" style={{
                          width: "100%",
                          fontSize: "17px",
                          fontFamily: "Barlow Condensed"
                        }}>
                          {category}
                        </b>
                      </h5>
                    </ScrollLink>
                  </div>
                );
              }
            })
          ) : (
            <></>
          )}
        </Nav>
      </Navbar>
    </>
  )
}

export default VapianoNavBar;
