import { useSelector } from "react-redux";
import Timer from "../components/payment-success/Timer";
import { v4 as uuidv4 } from "uuid";

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function convertMsToTime(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  // let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.

  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}

const checkTimes = (time) => {
  if (time < 1000000) {
    return "vapGreen";
  } else if (time > 1000000 && time < 2000000) {
    return "vapYellow";
  } else if (time > 2000000) {
    return "vapRed";
  }
};

const StationTimings = ({ setLiveModal }) => {
  const order = useSelector((state) => state.order.ordersPlaced);
  const blId = sessionStorage.getItem("blId");
  // setInterval(() => {
  //   getTimings()
  // }, 20000);

  const data = {
    blId: blId,
    stations: [
      { station: "Antipasti", time: 1000000 },
      { station: "Pasta", time: 1500000 },
      { station: "Pinsa", time: 2500000 },
      { station: "Insalate", time: 360000 }
    ]
  };

  return (
    <>
      <div className="dish-cross-modal d-flex align-items-center">
        <i
          className="fa fa-close fa-stack-1x fa-inverse text-white"
          onClick={() => {
            setLiveModal(false);
          }}></i>
      </div>

      <div className="modal-body">
        <div className="liveTop">
          <h1> AKTUELLE WARTEZEIT PRO STATION</h1>
          {/* <h3>ES IST EINE FRAGE DER ZEIT</h3> */}
        </div>
        {/*  */}
        <div className="liveBottom">
          {/* <p>
            WARTEZEIT IN JEDER STATION WIRD ALLE 15 SEK. IN ECHTZEIT
            AKTUALISIERT
          </p> */}
          <ul className="queueItem">
            {data.stations.map((stat) => {
              return (
                <>
                  <li key={uuidv4()}>
                    <div className="queueItemName">
                      <img src="/img/goto/mod3.png" alt="goto" />
                      <h1 className="text-uppercase">{stat.station}</h1>
                    </div>
                    <div className={`queueTime ${checkTimes(stat?.time)}`}>
                      {order.length > 0 ? (
                        <h1>
                          <Timer
                            duration={stat.time}
                            seconds
                            minutes
                            onCompletion={() => {
                              return <h2 className="d-inline"> 00 : 00</h2>;
                            }}
                          />
                        </h1>
                      ) : (
                        <h1 className="d-inline">{convertMsToTime(stat.time)}</h1>
                      )}
                      <span>
                        <i className="fa-solid fa-clock"></i>
                      </span>
                    </div>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default StationTimings;
