/* eslint-disable react/display-name */
import React, { memo } from "react";
import "../../assets/css/paymentsuccess.css";
import Countdown, { zeroPad } from "react-countdown";

const Timer = memo((props) => {
  // Redirect component
  const Completionist = () => {
    return props.onCompletion();
  };

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {props?.hours && zeroPad(hours)}
          {props?.hours && ":"}
          {props?.minutes && zeroPad(minutes)}
          {props?.minutes && ":"}
          {props?.seconds && zeroPad(seconds)}
        </span>
      );
    }
  };

  return (
    <>
      <Countdown date={Date.now() + props?.duration} renderer={renderer} />
    </>
  );
});

export default Timer;
