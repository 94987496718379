import { Container } from "react-bootstrap";

const Page404 = () => {
  return (
    <>
      <Container className="text-center">
        <img src="img/404_page_cover.jpg" alt="404 Not Found" className="img-fluid" />
        {/* <h1 className="text-dark p-5 text-center"><b><u>404 - NOT FOUND</u></b></h1> */}
      </Container>
    </>
  );
};

export default Page404;
