import { Button, Modal } from "react-bootstrap";
import React from "react";

const ModalInterface = (props) => {
  return (
    <Modal
      size={props.size}
      show={props.display}
      onHide={props.handleClose}
      className={`${props.classname && props.classname} p-md-5 p-0`}
      centered={props?.center}>
      {props?.title && (
        <Modal.Title className={props.className}>
          <h1>{props.title}</h1>
        </Modal.Title>
      )}

      <Modal.Body className={props.bodyClass} style={props.bodyStyles}>
        <>{props.content}</>
      </Modal.Body>

      {props.footer && (
        <Modal.Footer className="justify-content-end">
          {props.footerContent}
          <Button variant="danger" onClick={props.handleClose}>
            <h4>Close</h4>
          </Button>
        </Modal.Footer>
      )}

      {props.isCart && <Modal.Title className="p-2 ">{props.cartFooter}</Modal.Title>}
    </Modal>
  );
};

export default ModalInterface;
