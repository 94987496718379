import * as bsellerType from "../types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";

var bestSellerRepository = RepositoryFactory.get("getbsRepoFunc");

export const getBestSeller = (payload) => async (dispatch) => {
  try {
    const res = await bestSellerRepository.getbestSeller(payload);
    dispatch({
      type: bsellerType.GET_BEST_SELLER_SUCCESS,
      payload: res.data?.data
    });
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: bsellerType.GET_BEST_SELLER_FAIL,
      payload: error
    });
  }
};

export const resetBestSellerMenu = () => async (dispatch) => {
  dispatch({
    type: "RESET_BEST_SELLER"
  });
};
