// import { applyMiddleware, compose, createStore } from "redux";
import appReducer from "./reducers";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
// import thunk from "redux-thunk";
// const persistedState = localStorage.getItem("reduxState")
//   ? JSON.parse(localStorage.getItem("reduxState"))
//   : {};
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// let store = createStore(
//   rootReducer,
//   persistedState,
//   composeEnhancers(applyMiddleware(thunk))
// );

// store.subscribe(() => {
//   if (window.location.href.includes("table")) {
//     const blId = sessionStorage.getItem("blId")
//     localStorage.clear();
//     sessionStorage.clear();
//     if (blId) {
//       sessionStorage.setItem("blId", blId);
//     }
//   } else {
//     try {
//       localStorage.setItem("reduxState", JSON.stringify(store.getState()));
//       console.log("local storage supported");
//     } catch (ex) {
//       console.log("local storage not supported");
//     }
//   }
// });
// export default store;

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["table", "order"] // which reducer want to store
};

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    storage.removeItem("persist:root");

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const composeEnhancers = composeWithDevTools({});
const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer, composeEnhancers(applyMiddleware(thunk)));
const persistor = persistStore(store);
export { persistor, store };
