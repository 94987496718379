import { GET_SPECIAL } from "../types";

const initialState = {
  special: [],
  loading: true
};

export default function specialReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_SPECIAL: {
      let tempPayload = payload.map((item) => {
        return {
          ...item,
          ingredients: item?.ingredients.map((itx) => {
            let source = {
              isSingleOnly: false
            };

            Object.assign(itx, source);

            if (item.single_only.includes(itx?.group_id)) {
              itx.isSingleOnly = true;
            }

            if (itx.type === "Standard") {
              return { ...itx, selected: true };
            } else {
              return { ...itx, selected: false };
            }
          })
        };
      });
      return {
        ...state,
        special: tempPayload,
        loading: false
      };
    }

    case "RESET_SPECIAL":
      var tempSpecial = state.special.map((item) => {
        return {
          ...item,
          ingredients: item.ingredients.map((itx) => {
            var source = {
              isSingleOnly: false
            };

            Object.assign(itx, source);

            if (item.single_only.includes(itx?.group_id)) {
              itx.isSingleOnly = true;
            }

            if (itx.type === "Standard") {
              return { ...itx, selected: true };
            } else {
              return { ...itx, selected: false };
            }
          })
        };
      });
      return {
        ...state,
        special: tempSpecial,
        loading: false
      };

    default:
      return state;
  }
}
