import React, { useCallback, useEffect } from "react";
import Scanner from "../Scanner/Scanner";

const Scan = (props) => {
  const abortController = new AbortController();
  abortController.signal.onabort = () => {
    // All NFC operations have been aborted.
  };
  // const [message, setMessage] = useState("");
  // const [serialNumber, setSerialNumber] = useState("");
  const { actions, setActions } = props.actionsValue;
  const scan = useCallback(async () => {
    if ("NDEFReader" in window) {
      try {
        const ndef = new window.NDEFReader();
        await ndef.scan({ signal: abortController.signal });
        ndef.onreadingerror = () => {
          props.SetIsErrorMsg(true);
          props.setErrorMsgResponce("Cannot read data from the NFC tag. Try another one?");
        };
        ndef.onreading = (event) => {
          onReading(event);
          setActions({
            scan: "scanned",
            write: null
          });
        };
      } catch (error) {
        props.SetIsErrorMsg(true);
        props.setErrorMsgResponce(`Error! Scan failed to start: ${error}.`);
        setActions({ scan: null });
      }
    } else {
      setActions({
        scan: "",
        write: null
      });
      props.SetIsErrorMsg(true);
      props.setErrorMsgResponce("no NDEFReader in window ");
    }
  }, [setActions]);

  const onReading = ({ message }) => {
    const decoder = new TextDecoder();
    for (const record of message.records) {
      switch (record.recordType) {
        case "text":
          break;
        case "url":
          props.setTableName(decoder.decode(record.data));
          break;
        default:
        // TODO: Handle other records with record data.
      }
      setActions({ scan: null });
      abortController.abort();
    }
  };

  useEffect(() => {
    scan();
  }, [scan]);

  return (
    <>
      {actions.scan === "scanning" ? (
        <Scanner status={actions.scan} actionsValue={props.actionsValue}></Scanner>
      ) : (
        <div>
          {props.tableName && (
            <>
              {/* <p>Serial Number: {serialNumber}</p>
              <p>Message: {message}</p> */}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Scan;
