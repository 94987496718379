import {
  CHANGE_ING_STATUS,
  CREATE_TABLE,
  GET_ALLERGEN,
  GET_MENU,
  JOIN_TABLE,
  SET_CART_DATA,
  SET_SEARCH_RESULTS,
  SET_INGREDIENTS,
  SET_TABLE,
  SET_CART_INGREDIENTS,
  SET_VEGAN_ITEMS,
  SET_TAKEAWAY,
  GET_SINGLE_INGREDIENTS,
  SET_RELATED_PRODS,
  GET_DESCRIPTION_REQUEST,
  GET_DESCRIPTION_SUCCESS,
  GET_DESCRIPTION_FAIL
} from "../types";

const initialState = {
  menu: [],
  takeAway: false,
  ingredientsLoading: true,
  allergens: [],
  ingredients: [],
  cartIngredients: [],
  table: null,
  loading: true,
  cartData: {},
  searchResults: [],
  veganItems: [],
  description: "",
  stableIngredients: [],
  descriptionLoader: false
};

export default function menuReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CART_INGREDIENTS: {
      return {
        ...state,
        cartIngredients: payload
      };
    }
    case SET_INGREDIENTS: {
      var allCardData = state.cartData;
      allCardData["ingredients"] = payload.ingredients;
      allCardData["mandatory"] = payload.mandatory;
      allCardData["single_only"] = payload.single_only;
      return {
        ...state,
        ingredientsLoading: false,
        cartData: allCardData
      };
    }

    case SET_TAKEAWAY: {
      return {
        ...state,
        takeAway: payload
      };
    }

    case SET_CART_DATA: {
      payload?.ingredients?.forEach((ing) => {
        if (payload?.single_only?.includes(ing?.group_id)) {
          ing.isSingleOnly = true;
        }

        if (ing.type !== "Standard") {
          ing.selected = false;
          // state.cartData?.ingredients?.forEach(
          //   (value) => (value.selected = false)
          // );
        } else if (ing.type === "Standard") {
          // state.cartData?.ingredients?.forEach(
          //   (value) => (value.selected = true)
          // );
          ing.selected = true;
        }
      });

      return {
        ...state,
        cartData: payload,
        ingredientsLoading: true
      };
    }

    case GET_SINGLE_INGREDIENTS: {
      return {
        ...state,
        stableIngredients: payload,
        ingredientsLoading: false
      };
    }

    case "SELECT_ING_GROUP": {
      // Get 'current_groupID' (if exists) from cart
      // check if 'current_groupID' exists in 'single_only' array of the current item i.e payload.data
      // if found i.e itemIsSingleOnly then
      // a) find all the ingredients with same group id & udpate them as 'selected: false'
      // b) filter using 'group_id === payload.current_groupID' to find any ingredients in cart data & udpate them as 'selected: false'

      payload?.data.ingredients?.forEach((ing) => {
        if (ing?.group_id === payload?.groupID && payload?.singles_only.includes(payload.groupID)) {
          state.cartData?.ingredients
            ?.filter((ig) => ig?.group_id === payload?.groupID && ig.selected === true)
            .forEach((value) => (value.selected = false));
        }
      });

      return {
        ...state,
        cartData: payload.data
      };
    }

    case SET_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: payload.flat()
      };
    }

    case CHANGE_ING_STATUS: {
      let temp_menu = JSON.parse(JSON.stringify(state.menu));
      let vegan_menu = JSON.parse(JSON.stringify(state.menu));

      let parent = temp_menu?.find((item) => item._id === payload?.id);
      let veganParent = vegan_menu?.find((item) => item._id === payload?.id);
      let parentMenuIndex = temp_menu?.findIndex((item) => item._id === payload?.id);
      let veganMenuIndex = vegan_menu?.findIndex((item) => item._id === payload?.id);

      if (parentMenuIndex >= 0) {
        parent.ingredients[payload.index].selected = payload.status;

        temp_menu[parentMenuIndex] = parent;

        return {
          ...state,
          menu: temp_menu
        };
      } else if (veganMenuIndex >= 0) {
        veganParent.ingredients[payload.index].selected = payload.status;

        vegan_menu[veganMenuIndex] = veganParent;
        return {
          ...state,
          veganItems: vegan_menu
        };
      }

      return {
        ...state
      };
    }

    case GET_MENU: {
      let tempPayload = payload?.map((item) => {
        return {
          ...item,
          ingredients: item?.ingredients?.map((itx) => {
            // let source = {
            //   isSingleOnly: false
            // };

            //       Object.assign(itx, source);

            //       if (item.single_only.includes(itx?.group_id)) {
            //         itx.isSingleOnly = true;
            //       }

            if (itx.type === "Standard") {
              return { ...itx, selected: true };
            } else {
              return { ...itx, selected: false };
            }
          })
        };
      });

      return {
        ...state,
        loading: false,
        menu: tempPayload
      };
    }

    case "RESET_MENU": {
      let tempPayload = state?.menu?.map((item) => {
        return {
          ...item,
          ingredients: item?.ingredients?.map((itx) => {
            let source = {
              isSingleOnly: false
            };

            Object.assign(itx, source);

            if (item.single_only.includes(itx?.group_id)) {
              itx.isSingleOnly = true;
            }

            if (itx.type === "Standard") {
              return { ...itx, selected: true };
            } else {
              return { ...itx, selected: false };
            }
          })
        };
      });

      let tempVegan = state?.veganItems?.map((item) => {
        return {
          ...item,
          ingredients: item?.ingredients?.map((itx) => {
            let source = {
              isSingleOnly: false
            };

            Object.assign(itx, source);

            if (item.single_only.includes(itx?.group_id)) {
              itx.isSingleOnly = true;
            }

            if (itx.type === "Standard") {
              return { ...itx, selected: true };
            } else {
              return { ...itx, selected: false };
            }
          })
        };
      });

      return {
        ...state,
        loading: false,
        menu: tempPayload,
        veganItems: tempVegan,
        description: null
      };
    }

    case GET_ALLERGEN: {
      return {
        ...state,
        allergens: payload
      };
    }

    case GET_DESCRIPTION_REQUEST: {
      return {
        ...state,
        descriptionLoader: true
      };
    }
    case GET_DESCRIPTION_SUCCESS: {
      state.description = null;

      return {
        ...state,
        descriptionLoader: false,
        description: payload
      };
    }
    case GET_DESCRIPTION_FAIL: {
      return {
        ...state,
        descriptionLoader: false,
        description: ""
      };
    }
    case JOIN_TABLE: {
      return {
        ...state,
        table: payload
      };
    }

    case CREATE_TABLE: {
      return {
        ...state,
        table: payload
      };
    }

    case SET_TABLE: {
      return {
        ...state,
        table: payload
      };
    }

    case SET_VEGAN_ITEMS: {
      let tempPayload = payload?.data?.map((item) => {
        return {
          ...item,
          ingredients: item?.ingredients?.map((itx) => {
            let source = {
              isSingleOnly: false
            };

            Object.assign(itx, source);

            if (item.single_only.includes(itx?.group_id)) {
              itx.isSingleOnly = true;
            }

            if (itx.type === "Standard") {
              return { ...itx, selected: true };
            } else {
              return { ...itx, selected: false };
            }
          })
        };
      });
      return {
        ...state,
        veganItems: tempPayload
      };
    }

    case SET_RELATED_PRODS: {
      let updatedCart = { ...state.cartData, related_products: payload };
      return {
        ...state,
        cartData: updatedCart
      };
    }

    default:
      return state;
  }
}
