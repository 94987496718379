import React from "react";
import BillPrint from "../components/print/billprint";

const PrintRecipt = ({ setPrint }) => {
  setPrint(window.location.pathname);
  return (
    <>
      <BillPrint />
    </>
  );
};

export default PrintRecipt;
