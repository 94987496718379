import { GET_PROMOTION_SUCCESS, GET_PROMOTION_FAIL } from "../types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
// import axios from "axios"

var promotionRepository = RepositoryFactory.get("promotions");
export const getPromotion = (payload) => async (dispatch) => {
  try {
    const res = await promotionRepository.getPromotions(payload);
    dispatch({
      type: GET_PROMOTION_SUCCESS,
      payload: res.data?.data
    });
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: GET_PROMOTION_FAIL,
      payload: error
    });
  }
};
