import {
  SET_TABLE,
  BUS_INFO,
  ORDER_INFO,
  SESSION_ID,
  RESET_TABLE_SESSION,
  SET_TABLE_LOADING,
  SET_GUESTBOOK,
  GET_FLOOR_PLANS_SUCCESSED,
  UPDATE_SESSION_TABLE_REQUEST,
  UPDATE_SESSION_TABLE_SUCCESSED,
  UPDATE_SESSION_TABLE_FAIL,
  GET_TEXT,
  SET_TEXT,
  UPDATE_SESSION_PAGER_SUCCESSED,
  GET_TABLE_ENABLE_PERMISSION
} from "../types";

const initialState = {
  table: "",
  orderInfo: {},
  business_Info: { business_name: "", table_sitting_amount: 0 },
  loading: true,
  sessionId: "",
  isGuestBook: false,
  floorPlans: [],
  updateTableLoader: false,
  textLoader: false,
  modalText: [],
  isTablePermission: false,
};

export default function tableReducer(state = initialState, { type, payload }) {
  switch (type) {
    case RESET_TABLE_SESSION: {
      return {
        ...state,
        table: "",
        orderInfo: {},
        business_Info: { business_name: "", table_sitting_amount: 0 },
        sessionId: null,
        loading: true
      };
    }

    case SESSION_ID: {
      return {
        ...state,
        sessionId: payload.id
      };
    }

    case BUS_INFO: {
      return {
        ...state,
        business_Info: payload.data,
        loading: false
      };
    }

    case SET_TABLE: {
      return {
        ...state,
        table: payload
      };
    }

    case ORDER_INFO: {
      return {
        ...state,
        orderInfo: payload
      };
    }
    case SET_TABLE_LOADING: {
      return {
        ...state,
        loading: payload
      };
    }
    case SET_GUESTBOOK: {
      return {
        ...state,
        isGuestBook: payload
      };
    }
    case GET_FLOOR_PLANS_SUCCESSED: {
      return {
        ...state,
        floorPlans: payload
      };
    }
    case UPDATE_SESSION_TABLE_REQUEST: {
      return {
        ...state,
        updateTableLoader: true
      };
    }
    case UPDATE_SESSION_TABLE_SUCCESSED: {
      let orderInfo = state.orderInfo;
      orderInfo.tableNumber = payload;
      return {
        ...state,
        orderInfo: orderInfo,
        updateTableLoader: false
      };
    }
    case UPDATE_SESSION_TABLE_FAIL: {
      return {
        ...state,
        updateTableLoader: false
      };
    }
    case UPDATE_SESSION_PAGER_SUCCESSED: {
      let orderInfo = state.orderInfo;
      orderInfo.pagerNumber = payload;
      return {
        ...state,
        orderInfo: orderInfo,
      };
    }
    case GET_TEXT: {
      return {
        ...state,
        textLoader: true
      };
    }
    case SET_TEXT: {
      return {
        ...state,
        textLoader: false,
        modalText: payload
      };
    }
    case GET_TABLE_ENABLE_PERMISSION: {
      return {
        ...state,
        isTablePermission: payload
      };
    }

    default:
      return state;
  }
}
