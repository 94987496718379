import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetCartLength } from "../../store/actions/orderAction";
import BillNoItem from "../modals/BillnoItem";
import { checkIsGuestBook } from "../../store/actions/guestBookAction";

const NavigationDashboard = () => {
  const orders = useSelector((state) => state.order);
  const [isTotalOrderTimeOut, setIsTotalOrderTimeOut] = useState(false);
  const dispatch = useDispatch();
  const [billModal, setBillModal] = useState(false);
  const orderCount = orders?.ordersPlaced.length;
  const isGuestBook = useSelector((state) => state.table.isGuestBook);
  const page_theme = useSelector(
    (state) => state.table?.business_Info?.page_layout?.instore?.footer
  );

  useEffect(() => {
    dispatch(checkIsGuestBook());
  }, []);

  useEffect(() => {
    dispatch(GetCartLength());
    if (orders.Carts.length) {
      setIsTotalOrderTimeOut(false);
      checkTime(new Date(orders?.Carts[0]?.endTime));
    }
  }, [orders.Carts.length]);
  const checkTime = (end) => {
    let interval = setInterval(() => {
      var now = new Date();
      var distance = end - now;
      if (distance < 0) {
        setIsTotalOrderTimeOut(true);
        clearInterval(interval);
        return;
      }
    }, 1000);
  };
  return (
    <>
      <Navbar
        fixed="bottom"
        expand="lg"
        style={{
          backgroundColor: page_theme?.primary_bgcolor,
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "20px",
          width: "100%"
        }}
        className="justify-content-between">
        <NavLink
          to="/home"
          className={({ isActive }) =>
            isActive
              ? "text-decoration-none text-center align-items-center d-flex flex-column active-navlink"
              : "text-decoration-none text-white text-center d-flex flex-column align-items-center inactive-navlink"
          }>
          {() => {
            return (
              <>
                <img src="img/boname-footer/menu.fw.png" height={30} width={30} />
                <span
                  style={{
                    fontStyle: page_theme?.primary_text_font,
                    fontFamily: page_theme?.primary_text_font_family,
                    color: page_theme?.primary_text_color
                  }}>
                  SPEISEKARTE
                </span>
              </>
            );
          }}
        </NavLink>
        <NavLink
          to="/order"
          style={{ position: "relative" }}
          className={({ isActive }) =>
            isActive
              ? "flex-column  d-flex text-decoration-none text-center text-info align-items-center active-navlink"
              : " d-flex flex-column text-decoration-none text-white text-center align-items-center inactive-navlink"
          }>
          {orders?.Carts.length > 0 && (
            <span
              className="countTotalOrder totalOrder"
              style={
                isTotalOrderTimeOut
                  ? {
                    display: "block",
                    animation: "totalOrder 1s infinite"
                  }
                  : { display: "block" }
              }>
              {orders?.Carts.length}
            </span>
          )}
          <img src={"img/boname-footer/cart.fw.png"} height={30} width={30} />

          {/* </> */}

          {/* <i
            className="fa fa-shopping-cart fa-xl mb-2 d-block"
            aria-hidden="true"
          >
            {orders?.numberOfOrdersInCart != 0 && (
              <span className="text-primary p-2 d-inline bg-light rounded-circle">
                {orders?.numberOfOrdersInCart != 0 &&
                  orders?.numberOfOrdersInCart}
              </span>
            )}
          </i> */}

          <span
            style={{
              fontStyle: page_theme?.primary_text_font,
              fontFamily: page_theme?.primary_text_font_family,
              color: page_theme?.primary_text_color
            }}>
            WARENKORB
          </span>
        </NavLink>

        <NavLink
          to={orderCount > 0 && "/bill"}
          className={({ isActive }) =>
            orderCount > 0 && isActive
              ? " d-flex flex-column text-decoration-none text-center align-items-center active-navlink-bill"
              : " d-flex flex-column text-decoration-none text-white text-center align-items-center inactive-navlink"
          }
          onClick={() => {
            if (orderCount === 0) setBillModal(true);
          }}>
          <img src={"img/boname-footer/bill.fw.png"} height={30} width={30} />
          {/* <i
            className="fa fa-file-invoice d-block fa-xl mb-2"
          ></i> */}
          <span
            className="text-center"
            style={{
              fontStyle: page_theme?.primary_text_font,
              fontFamily: page_theme?.primary_text_font_family,
              color: page_theme?.primary_text_color
            }}>
            RECHNUNG{" "}
          </span>
        </NavLink>

        {isGuestBook && (
          <NavLink
            to="/guestBook"
            className={({ isActive }) =>
              isActive
                ? "text-decoration-none text-center align-items-center active-navlink-guest"
                : "text-decoration-none text-white text-center align-items-center inactive-navlink"
            }>
            {/* <i className="fa-solid fa-book d-block fa-xl mb-2"></i> */}
            <div>
              <img src={"img/boname-footer/guestbook.fw.png"} height={30} width={30} />
            </div>
            <span
              style={{
                fontStyle: page_theme?.primary_text_font,
                fontFamily: page_theme?.primary_text_font_family,
                color: page_theme?.primary_text_color
              }}>
              GÄSTEBUCH
            </span>
          </NavLink>
        )}

        <NavLink
          to="/service"
          className={({ isActive }) =>
            isActive
              ? "text-decoration-none text-center align-items-center active-navlink-service"
              : "text-decoration-none text-white text-center align-items-center inactive-navlink"
          }>
          {/* <i className="fas fa-hands-helping d-block fa-xl mb-2"></i> */}
          <div>
            <img src={"img/boname-footer/service.fw.png"} height={30} width={30} />
          </div>
          <span
            style={{
              fontStyle: page_theme?.primary_text_font,
              fontFamily: page_theme?.primary_text_font_family,
              color: page_theme?.primary_text_color
            }}>
            SERVICE
          </span>
        </NavLink>
      </Navbar>
      <BillNoItem billModal={billModal} setBillModal={setBillModal} />
    </>
  );
};

export default NavigationDashboard;
