import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { trimItemName } from "../../common/TrimStrings";
import {
  getSingleIngredients,
  loadDescription,
  SetCartData as SetCartDataAction
} from "../../store/actions/menuAction";
import { Sync_Carts } from "../../store/actions/orderAction";
import OrderDetails from "../order/orderdetails";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Navigation, Pagination, Mousewheel, Keyboard, EffectCoverflow} from 'swiper/modules';


const VeganVegetarian = ({
  page_theme,
  veganItems,
  setShowCart,
  id,
  setInfoAllergens,
  setAllergenData,
  swiping
}) => {
  const dispatch = useDispatch();
  const busId = useSelector((state) => state?.table?.orderInfo?.businessLocationId);

  const [testImg] = useState([
    "./img/PlatenKnife.png"
    // "https://appapi1.va-piano.ch/5.jpg",
    // "https://appapi1.va-piano.ch/1.jpg",
  ]);

  return (
    <>
      {/*{/*Vegan & Vegetarian start */}
      <Row className="w-100 gx-0 gy-0 vegitarian" id={id}>
        <Col md="12" sm="12" lg="12" xs="12">
          <div className="sectionHdng">
            <div className="hdngflex">
              <h1
                style={{ color: page_theme?.primary_title_vegan_color }}
                className={`font-${page_theme?.font_family_vegan_title} fs-${page_theme?.font_size_vegan_title}`}>
                vegan
              </h1>
            </div>
            <div className="hdngWrap ">
              <div
                style={{
                  backgroundColor: page_theme?.primary_line_vegan_color
                }}
                className="hdnglineblue"></div>
              <img className="leafsImg" src={"/img/mod6.png"} alt="icon" />
            </div>
          </div>
        </Col>
        <Col md="12" sm="12" lg="12" xs="12" style={{  position: 'relative'}}>
          {veganItems?.length > 0 && (
              <Swiper
                  cssMode={true}
                  navigation={true}
                  mousewheel={true}
                  keyboard={true}
                  modules={[Navigation, Pagination, Mousewheel, Keyboard,EffectCoverflow ]}
                  className="mySwiper"
                  slidesPerView={2.5}
                  spaceBetween={0}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 0,
                  }}
              >
              {veganItems?.map((data) => {
                return (
                    <SwiperSlide key={uuidv4()}  style={{
                      cursor: "pointer"
                    }}
                                 className="dish-wrapper">
                    <h5
                      className={`menu-itemName d-flex justify-content-center mb-2 fs-${page_theme?.font_size_menu_item_title} font-${page_theme?.font_family_menu_item_title}`}
                      style={{
                        color: page_theme?.primary_menu_item_title_color
                      }}>
                      {window.innerWidth <= 500 && window.innerHeight <= 900
                        ? trimItemName(data?.name)
                        : data?.name}
                    </h5>
                    <div className="p-1 dish-img-container">
                      <img
                        style={{ cursor: "pointer" }}
                        src={data?.image || testImg[0]}
                        // className=" dish-img"
                        className="dish-img1"
                        alt="menu-item"
                        onClick={() => {
                          if (!swiping) {
                            dispatch(
                              SetCartDataAction(
                                data
                                //  ingredients: stableIngredients
                              )
                            );

                            dispatch(
                              loadDescription({
                                sku: data.sku,
                                blId: busId
                              })
                            );

                            dispatch(Sync_Carts(data));
                            setShowCart(true);
                            dispatch(
                              getSingleIngredients({
                                sku: data.sku,
                                blId: busId
                              })
                            );
                          }
                        }}
                      />

                      {/* <img
                        src="/img/hearticon.png"
                        className="heartImg"
                        alt="hearticon"
                      /> */}
                      {data.isBestSeller === true ? (
                        <img
                          src="/img/hearticon.png"
                          className="heartImg"
                          alt="hearticon"
                          onClick={() => {
                            setInfoAllergens(true);
                          }}
                        />
                      ) : (
                        <img
                          src="/img/hearticon.png"
                          className="heartImg invisible"
                          alt="hearticon"
                        />
                      )}
                    </div>

                    <OrderDetails
                      page_theme={page_theme}
                      data={data}
                      setInfoAllergens={setInfoAllergens}
                      setAllergenData={setAllergenData}
                    />
                </SwiperSlide>
                );
              })}

              </Swiper>
              // <Slider {...settings}>

            // </Slider>
          )}
        </Col>
      </Row>
      {/* Vegan & Vegetarian Fin */}
    </>
  );
};

export default VeganVegetarian;
