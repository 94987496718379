export const checkMandatorySelection = (cartData) => {
  function getDifference(setA, setB) {
    return new Set([...setA]?.filter((element) => !setB?.has(element)));
  }

  let groupNames = [];

  let selectedIngredients = new Set();

  cartData?.ingredients?.forEach((subItem) => {
    if (subItem?.selected) {
      selectedIngredients?.add(subItem?.group_id);
    }
    if (cartData?.mandatory?.includes(subItem?.group_id)) {
      return groupNames.push({
        groupName: subItem?.group,
        id: subItem?.group_id
      });
    }
  });

  let mandatoryItems = new Set(cartData?.mandatory);

  let missedGroups = getDifference(mandatoryItems, selectedIngredients);

  if (missedGroups?.size === 0) {
    return { checkedItems: true };
  } else {
    let missing = new Set();
    groupNames.forEach((group) => {
      if (missedGroups.has(group?.id)) missing.add(group?.groupName);
    });
    return {
      checkedItems: false,
      groups: Array?.from(missing)
    };
  }
};

export const getCalculatedPricing = (orders, cartData) => {
  const indexOfItem = orders.tempCart?.findIndex((item) => item?.sku === cartData?.sku);
  let item = orders?.tempCart[indexOfItem];

  let ingredientPrices = [];
  let totalItemPrice = 0;

  let price = (item?.price * item?.quantity)?.toFixed(2);

  let te = cartData?.ingredients?.filter((tr) => tr?.selected === true);

  te?.filter((ingredient_) => {
    return ingredient_?.selected === true;
  }).forEach((ing) => {
    return ingredientPrices.push(Number(ing?.price) * Number(item?.quantity));
  });

  let restt = ingredientPrices?.reduce(function (a, b) {
    return a + b;
  }, 0);

  if (typeof restt !== "number" || isNaN(restt)) {
    totalItemPrice = Number(price);
  } else {
    totalItemPrice = Number(restt) + Number(price);
  }

  return totalItemPrice?.toFixed(2);
};
