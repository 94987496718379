import { POST_CANVAS, GRID_CANVAS, SET_LOADING_CANVAS } from "../types";

const initialState = {
  canvasPost: undefined,
  canvasGrid: [],
  loading: true
};

export default function canvasReducer(state = initialState, { type, payload }) {
  switch (type) {
    case POST_CANVAS: {
      return {
        ...state,
        canvasPost: payload,
        loading: false
      };
    }
    case GRID_CANVAS: {
      return {
        ...state,
        canvasGrid: payload,
        loading: false
      };
    }

    case SET_LOADING_CANVAS: {
      return {
        ...state,
        loading: payload
      };
    }
    default:
      return state;
  }
}
