import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Scan from "../Scane/Scan";
import { useNavigate } from "react-router-dom";
import ModalInterface from "../../common/ModalInterface";

const CreateSession = () => {
  const [actions, setActions] = useState(null);
  const [tableName, setTableName] = useState("");
  const [isErrorMsg, SetIsErrorMsg] = useState(false);
  const [errorMsgResponce, setErrorMsgResponce] = useState();
  const { scan } = actions || {};
  const onHandleAction = (actions) => {
    setActions({ ...actions });
  };
  const navigate = useNavigate();
  const actionsValue = { actions, setActions };
  useEffect(() => {
    if (tableName) {
      let urlParams = tableName;
      let url_parts = urlParams?.split("=");
      let idc = url_parts[url_parts?.length - 1];
      let lowercase_idc = idc?.toLocaleLowerCase();
      let new_url = urlParams?.replace(idc, lowercase_idc);
      navigate("/?" + new_url?.toString());
    }
  }, [tableName]);
  return (
    <>
      <Row className="wrapper">
        <Col md="12" lg="12" sm="12" xs="12" className="">
          <div
            style={{ flexDirection: "column" }}
            className="d-flex align-items-center justify-content-center flex-direction-column">
            <div className="btn-show-card">
              <div>
                <div
                  onClick={() => onHandleAction({ scan: "scanning", write: null })}
                  className="card-btn ">
                  *Hier klicken, um zu bestellen
                </div>
              </div>
              {scan && (
                <Scan
                  tableName={tableName}
                  setTableName={setTableName}
                  actionsValue={actionsValue}
                  SetIsErrorMsg={SetIsErrorMsg}
                  setErrorMsgResponce={setErrorMsgResponce}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
      <ModalInterface
        display={isErrorMsg}
        size="lg"
        center="true"
        handleClose={async () => {
          SetIsErrorMsg(false);
        }}
        title={
          <>
            <div className="d-flex justify-content-end p-2">
              <span
                className="bg-danger rounded-circle p-2 d-flex align-items-center justify-content-center text-white"
                style={{ height: "25px", width: "25px" }}>
                {" "}
                <i
                  role="button"
                  className="fa fa-close fs-4"
                  onClick={async () => {
                    SetIsErrorMsg(false);
                  }}></i>
              </span>
            </div>
          </>
        }
        content={
          <>
            <h1 className="text-center">{errorMsgResponce}</h1>
          </>
        }
      />
    </>
  );
};

export default CreateSession;
