import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { orderInfo, setTable, tableID } from "./tableActions";
const UserRepository = RepositoryFactory.get("user");
export const createSession = (payload) => async (dispatch) => {
  try {
    let user = await UserRepository.createTable(payload);
    if (user) {
      dispatch(tableID({ id: user?.data?._id }));
      localStorage.setItem("user", JSON.stringify(user.data.table_name));
      sessionStorage.setItem("session_type", "create");
      dispatch(setTable(user.data.table_name));
      dispatch(
        orderInfo({
          businessLocationId: payload.buid,
          tableNumber: payload.table_no,
          clientCount: 1
        })
      );
      return user;
    }
  } catch (err) {
    console.log("error " + err);
    return err;
  }
};
export const joinSession = (payload) => async (dispatch) => {
  try {
    let table = await UserRepository.checkUser(payload);
    if (table) {
      dispatch(tableID({ id: table.data[0]._id }));
      localStorage.setItem("table", JSON.stringify(table));
      dispatch(setTable(table?.data[0]?.table_name));
      dispatch(orderInfo({ businessLocationId: payload?.buid, tableNumber: table?.data[0]?.table_id, cardId: (payload?.idc)?.toLowerCase(), pagerNumber: table?.data[0]?.pagerNumber }));
      sessionStorage.setItem("session_type", "join");
      return table;
    }
  } catch (err) {
    console.log("error " + err);
    return err;
  }
};
