import ImageRepository from "./ImageRepository";
import UserRepository from "./UserRepository";
import SpecialRepository from "./SpecialRepository";
import Promotions from "./PromotionRepository";
import BestSeller from "./bestSellerRepository";
import VeganItems from "./veganItemsRepository";
import Description from "./menuRepository";
import Ingredients from "./menuRepository";
import Order from "./OrderRepository";

const repositories = {
  img: ImageRepository,
  user: UserRepository,
  special: SpecialRepository,
  promotions: Promotions,
  getbsRepoFunc: BestSeller,
  getvegan: VeganItems,
  getDescription: Description,
  getIngredients: Ingredients,
  order: Order
};

export const RepositoryFactory = {
  get: (name) => repositories[name]
};
