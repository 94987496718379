import React from "react";

const OrderPlacedModal = () => {
  return (
    <>
      <section className="credit">
        <div className="divideCalc3">
          <h1>
            Ein Kellner kommt zu
            <br /> Ihrem Tisch
          </h1>
          {/* <span>your table</span> */}
        </div>
      </section>
      {/* <button
        className="okBtn"
        onClick={() => {
          props.setmodalProceed(true);
        }}
      >
        Proceed
      </button> */}
    </>
  );
};

export default OrderPlacedModal;
