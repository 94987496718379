import { GET_BEST_SELLER_SUCCESS, GET_BEST_SELLER_FAIL } from "../types";
import { v4 as uuidv4 } from "uuid";

const initialStata = {
  loading: false,
  bestSeller: []
};
export default function bestSellerReducer(state = initialStata, { type, payload }) {
  switch (type) {
    case GET_BEST_SELLER_SUCCESS:
      state.loading = true;
      var tempPayload = payload.map((item) => {
        function UUIdToNumber(string) {
          var numsStr = string.match(/\d/g);
          var joined = numsStr.join("");
          return Number(joined);
        }

        let uid = parseInt(UUIdToNumber(uuidv4()));
        uid = Math.trunc(uid * 100) / 100;

        return {
          ...item,
          id: uid,
          ingredients: item?.ingredients.map((itx) => {
            if (item.single_only.includes(itx?.group_id)) {
              itx.isSingleOnly = true;
            }

            if (itx.type === "Standard") {
              return { ...itx, selected: true };
            } else {
              return { ...itx, selected: false };
            }
          })
        };
      });
      return {
        ...state,
        bestSeller: tempPayload,
        loading: false
      };

    case "RESET_BEST_SELLER":
      var tempSeller = state.bestSeller.map((item) => {
        return {
          ...item,
          ingredients: item.ingredients.map((itx) => {
            if (item.single_only.includes(itx?.group_id)) {
              itx.isSingleOnly = true;
            } else {
              itx.isSingleOnly = false;
            }

            if (itx.type === "Standard") {
              return { ...itx, selected: true };
            } else {
              return { ...itx, selected: false };
            }
          })
        };
      });
      return {
        ...state,
        bestSeller: tempSeller,
        loading: false
      };

    case GET_BEST_SELLER_FAIL:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
