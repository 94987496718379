//Order
export const ORDER_INFO = "ORDER_INFO";
export const POST_ORDER = "POST_ORDER";
export const GET_COURSES = "GET_COURSES";
export const CHANGE_ING_STATUS = "CHANGE_ING_STATUS";
export const UPDATE_CART_COURSE = "UPDATE_CART_COURSE";
export const SYNC_CARTS = "SYNC_CARTS";
export const GET_ORDERS_BY_SESSION = "GET_ORDERS_BY_SESSION";
export const SET_LOADING = "SET_LOADING";
export const EMPTY_ORDERS_PLACED = "EMPTY_ORDERS_PLACED";
export const SET_PLACED_LOADING = "SET_PLACED_LOADING";
export const SET_ORDER_SUCCESS = "SET_ORDER_SUCCESS";
export const GET_ORDERS_BY_FIREBASE = "GET_ORDERS_BY_FIREBASE";
//Order Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_TEMP_CART = "ADD_TO_TEMP_CART";
export const UPDATE_CART = "UPDATE_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const numberOfOrdersInCart = "numberOfOrdersInCart";
export const ADD_INGREDIENTS = "ADD_INGREDIENTS";
export const DELETE_INGREDIENTS = "DELETE_INGREDIENTS";
export const GET_CART_LENGTH = "GET_CART_LENGTH";

//Order Payment
export const ORDER_PAYMENT = "ORDER_PAYMENT";
export const SET_ORDER_PAYMENT = "SET_ORDER_PAYMENT";
export const SET_PAYMENT_STATUS = "SET_PAYMENT_STATUS";

//Menu
export const GET_MENU = "GET_MENU";
export const GET_ALLERGEN = "GET_ALLERGEN";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SET_CART_DATA = "SET_CART_DATA";
export const GET_SINGLE_INGREDIENTS = "GET_SINGLE_INGREDIENTS";
export const SET_INGREDIENTS = "SET_INGREDIENTS";
export const SET_CART_INGREDIENTS = "SET_CART_INGREDIENTS";
export const SET_VEGAN_ITEMS = "SET_VEGAN_ITEMS";
export const SET_TAKEAWAY = "SET_TAKEAWAY";
export const SET_RELATED_PRODS = "SET_RELATED_PRODS";
export const GET_TEXT = "GET_TEXT";
export const SET_TEXT = "SET_TEXT";
export const GET_DESCRIPTION_REQUEST = "GET_DESCRIPTION_REQUEST";
export const GET_DESCRIPTION_SUCCESS = "GET_DESCRIPTION_SUCCESS";
export const GET_DESCRIPTION_FAIL = "GET_DESCRIPTION_FAIL";
// Special
export const GET_SPECIAL = "GET_SPECIAL";
export const SPECIALS_FAIL = "SPECAILS_FAIL";

//Table
export const JOIN_TABLE = "JOIN_TABLE";
export const CREATE_TABLE = "CREATE_TABLE";
export const SET_TABLE = "SET_TABLE";
export const BUS_INFO = "BUS_INFO";
export const SESSION_ID = "SESSION_ID";
export const RESET_TABLE_SESSION = "RESET_TABLE_SESSION";
export const SET_TABLE_LOADING = "SET_TABLE_LOADING";
export const SET_GUESTBOOK = "SET_GUESTBOOK";
export const GET_FLOOR_PLANS_SUCCESSED = "GET_FLOOR_PLANS_SUCCESSED";
export const UPDATE_SESSION_TABLE_REQUEST = "UPDATE_SESSION_TABLE_REQUEST";
export const UPDATE_SESSION_TABLE_SUCCESSED = "UPDATE_SESSION_TABLE_SUCCESSED";
export const UPDATE_SESSION_TABLE_FAIL = "UPDATE_SESSION_TABLE_FAIL";
export const UPDATE_SESSION_PAGER_SUCCESSED = "UPDATE_SESSION_PAGER_SUCCESSED";
export const GET_TABLE_ENABLE_PERMISSION = "GET_TABLE_ENABLE_PERMISSION"
// GuestBook Canvas
export const POST_CANVAS = "POST_CANVAS";
export const GRID_CANVAS = "GRID_CANVAS";
export const SET_LOADING_CANVAS = "SET_LOADING_CANVAS";

// set promotion
export const GET_PROMOTION_SUCCESS = "GET_PROMOTION_SUCCESS";
export const GET_PROMOTION_FAIL = "GET_PROMOTION_FAIL";
// get best seller
export const GET_BEST_SELLER_SUCCESS = "GET_BEST_SELLER_SUCCESS";
export const GET_BEST_SELLER_FAIL = "GET_BEST_SELLER_FAIL";
