//import firebase from "firebase";
import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};
export const firebase = app.initializeApp(firebaseConfig);
export const auth = app.auth();
export const db = app.database();
export const firestore = app.firestore();
// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

//export default firebase;
