import React, { useEffect, useState } from "react";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import "../assets/css/welcome.css";
import "../assets/css/style.css";
import "../assets/css/font-size.css";
import "../assets/css/font-family.css";
import NFCScane from "../components/welcome/NFCScane";
import SplashScreen from "../components/welcome/SplashScreen";
import ModalInterface from "../common/ModalInterface";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessFlorrPlans,
  getBusinessInfo,
  getTextAndHeading,
  setTableLoading
} from "../store/actions/tableActions";
import { getPromotion } from "../store/actions/promtionsActions";
import { ChangeTakeawayStatus, getTakeAwayMenu, menuAction } from "../store/actions/menuAction";
import { getBestSeller } from "../store/actions/bestsellerAction";
import { joinSession } from "../store/actions/userAction";

const ImageRepository = RepositoryFactory.get("img");

const Welcome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useSelector((state) => state.table.business_Info);
  const loadingScreen = useSelector((state) => state.table.loading);

  const [showWrapper, setShowWrapper] = useState(true);
  const [fixURL, setFixURL] = useState(false);
  const [isErrorMsg, SetIsErrorMsg] = useState(false);
  const [errorMsgResponce, setErrorMsgResponce] = useState();
  let [queryValue] = useSearchParams();
  let table_no = queryValue.get("table_no");
  let buid = queryValue.get("buid");
  let idc = queryValue.get("idc");
  let takeAwayStatus = queryValue.get("takeaway");

  let searchParams = window.location.search;

  let validateQuery = searchParams.includes("buid") && searchParams.includes("table_no");

  useEffect(() => {
    if (
      searchParams.length >= 0 &&
      validateQuery &&
      table_no !== "" &&
      String(table_no) !== "null" &&
      String(table_no) !== "undefined" &&
      table_no.length !== 0 &&
      String(buid) !== "null" &&
      buid.length !== 0 &&
      String(buid) !== "undefined" &&
      buid !== "" &&
      idc
    ) {
      dispatch(setTableLoading(true));
      setFixURL(false);
      dispatch({
        type: "LOG_OUT"
      });
      if (takeAwayStatus === "true") {
        dispatch(ChangeTakeawayStatus(takeAwayStatus));
        dispatch(getTakeAwayMenu(buid));
      } else {
        dispatch(menuAction(buid));
        store.best_seller === true && dispatch(getBestSeller(buid));
      }
      dispatch(getPromotion(buid));
      dispatch(getTextAndHeading(buid));
      dispatch(getBusinessFlorrPlans(buid));
      dispatch(getBusinessInfo({ table_no, buid })).then((res) => {
        if (res.success) {
          joinSessionHandler();
        }
      });
      setTimeout(() => {
        // hide wrapper
        setShowWrapper(false);
      }, 7000);
      // load logo
      ImageRepository.getLogo().then(() => { });
    } else {
      // dispatch(setTableLoading(false));
      setFixURL(false);
    }
  }, [searchParams]);
  const joinSessionHandler = () => {
    if (!idc && !table_no && !buid) {
      return;
    }
    let lowercase_idc = idc?.toLocaleLowerCase();
    dispatch(joinSession({ idc: lowercase_idc, table_no, buid })).then((resp) => {
      if (resp?.success) {
        navigate("/home");
      } else {
        setErrorMsgResponce("DIESE RUNDENNAME EXISTIERT NICHT");
        SetIsErrorMsg(true);
      }
    });
  };
  useEffect(() => {
    let tbId = table_no ? table_no : "1";
    let bid = buid ? buid : "771247277342722";
    dispatch(getBusinessInfo({ table_no: tbId, buid: bid }));
    dispatch(getPromotion(bid));
  }, []);
  return loadingScreen ? (
    <div className="position-relative min-vh-100 w-100">
      <span
        className="spinner-border spinner-border-lg text-danger text-center position-absolute top-50 start-50"
        role="status"
        aria-hidden="true"></span>
    </div>
  ) : (
    <>
      {fixURL && (
        <ModalInterface
          fullscreen={true}
          display={true}
          title={<div className="text-center text-danger">Fail!</div>}
          content={<div className="text-dark text-center">Please enter correct URL</div>}
        />
      )}

      {store.animation ? (
        <SplashScreen mainLogo={store.business_logo} showWrapper={showWrapper} />
      ) : null}

      {/* <div className={`${store.animation} ? 'afterAnimation bg-white : bg-white' `}>
       */}
      <div
        style={{
          backgroundColor: store?.body_bgcolor
        }}>
        <div className="logo">
          <div className="mainLogo">
            <img
              src={store.business_logo}
              alt="main_logo"
              width="85%"
              style={{ maxWidth: "280px", verticalAlign: "middle" }}
            />
          </div>
        </div>

        <main className="height-97 d-flex justify-content-center align-items-center mt-5">
          {<NFCScane />}
        </main>
      </div>
      <ModalInterface
        display={isErrorMsg}
        size="lg"
        center="true"
        handleClose={async () => {
          SetIsErrorMsg(false);
        }}
        title={
          <>
            <div className="d-flex justify-content-end p-2">
              <span
                className="bg-danger rounded-circle p-2 d-flex align-items-center justify-content-center text-white"
                style={{ height: "25px", width: "25px" }}>
                {" "}
                <i
                  role="button"
                  className="fa fa-close fs-4"
                  onClick={async () => {
                    SetIsErrorMsg(false);
                  }}></i>
              </span>
            </div>
          </>
        }
        content={
          <>
            <h1 className="text-center">{errorMsgResponce}</h1>
          </>
        }
      />
    </>
  );
};

export default Welcome;
