import { GRID_CANVAS, POST_CANVAS, SET_LOADING_CANVAS } from "../types";
import axios from "axios";

export const canvasPost = (payload) => async (dispatch) => {
  dispatch(setLoadingCanvas(true));
  try {
    let { url, ...exclude } = payload;
    // url=url.split(',')[1]
    let { data } = await axios.post(`${process.env.REACT_APP_BackendURL}/guest-wall`, {
      ...exclude,
      url: url.split(",")[1]
    });

    dispatch({
      type: POST_CANVAS,
      payload: data
    });
  } catch (err) {
    dispatch(setLoadingCanvas(false));
    alert("Please try again:" + err);
  }
};

export const setLoadingCanvas = (payload) => async (dispatch) => {
  dispatch({ type: SET_LOADING_CANVAS, payload: payload });
};

export const canvasGrid = (payload) => async (dispatch) => {
  dispatch(setLoadingCanvas({ type: SET_LOADING_CANVAS, payload: true }));
  try {
    let res = await axios.get(`${process.env.REACT_APP_BackendURL}/guest-wall/${payload}`);

    dispatch({
      type: GRID_CANVAS,
      payload: res?.data
    });
  } catch (err) {
    dispatch(setLoadingCanvas({ type: SET_LOADING_CANVAS, payload: false }));
    alert("ERROR HEREE (canvasGrid)" + err);
  }
};
