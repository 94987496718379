import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/paymentsuccess.css";
import {
  makeOrderPayment,
  SetLoading,
  loadPayAllOrdersBySession,
  listentoOrderChanges
} from "../../store/actions/orderAction";
import PaymentSuccessPartial from "./PaymentSuccessPartial";
import PaymentSuccessComplete from "./PaymentSuccessComplete";
import { useLocation } from "react-router-dom";
const PaymentSuccess = () => {
  let dispatch = useDispatch();
  const loading = useSelector((state) => state.order.loading);
  const fullPayment = useSelector((state) => state.order.paymentStatus);
  // const fullPayment = window.localStorage.getItem("paymentStatus");
  const table_no = useSelector((state) => state.table.orderInfo.tableNumber);

  const paymentInfo = useSelector((state) => state.order.orderPaymentInfo);
  const buid = useSelector((state) => state.table.orderInfo.businessLocationId);
  const sessionId = useSelector((state) => state.table.sessionId);
  const location = useLocation();
  const payment_type = location?.state?.payment_type;
  useEffect(() => {
    dispatch(SetLoading(true));
    if ((paymentInfo !== null || paymentInfo !== undefined) && payment_type !== "pay_waiter") {
      dispatch(makeOrderPayment(paymentInfo));
      dispatch(listentoOrderChanges(sessionId));
    }
  }, []);

  useEffect(() => {
    // dispatch(SetLoading(true));
    if (fullPayment) {
      dispatch(
        loadPayAllOrdersBySession({
          businessLocationId: buid,
          sessionId: sessionId
        })
      );
    }
  }, [fullPayment]);

  return (
    <div className="bgcolor-white">
      {payment_type === "pay_waiter" ? (
        <PaymentSuccessComplete buid={buid} table_no={table_no} payment_type={payment_type} />
      ) : (
        <>
          {loading || fullPayment == null ? (
            <div className="position-relative min-vh-100 w-100">
              <span
                className="spinner-border spinner-border-lg text-danger text-center position-absolute top-50 start-50"
                role="status"
                aria-hidden="true"></span>
            </div>
          ) : (
            <>
              {fullPayment === false && <PaymentSuccessPartial />}
              {fullPayment === true && <PaymentSuccessComplete buid={buid} table_no={table_no} />}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentSuccess;
