import React, { useEffect } from "react";
import "../../assets/css/paymentsuccess.css";
import "../../assets/css/paymentfail.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SetLoading } from "../../store/actions/orderAction";

const PaymentFail = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const orderLoading = useSelector((state) => state.order.loading);

  useEffect(() => {
    if (orderLoading) {
      dispatch(SetLoading(false));
    }
    setTimeout(() => {
      navigate("/bill");
    }, 3000);
  }, []);

  return (
    <>
      <div className="bgcolor-white paymentFail pt-0 d-flex gap-5 flex-column align-items-center justify-content-center">
        <h1> Your Payment Fail</h1>
        <h5>
          Redirecting{" "}
          <span
            className="spinner-border spinner-border-lg text-danger text-center"
            role="status"
            aria-hidden="true"
          ></span>
        </h5>
        <button
          onClick={() => {
            navigate("/bill");
          }}
          className="btn btn-info text-light"
        >
          BESTELLUNG
        </button>
      </div>
    </>
  );
};

export default PaymentFail;
