import React, { useEffect, useState } from "react";
import { Stack, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  postOrder,
  SetLoading,
  setOrderSuccess,
  loadOrdersBySession
} from "../store/actions/orderAction";
import { Sortable } from "../components/order/Sortable";
import { v4 as uuidv4 } from "uuid";
import ModalInterface from "../common/ModalInterface";
// import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
// import PreviousOrders from "../components/order/previousorders";
import PreviousOrderDesing from "../components/order/previousOrderDesing";
import { updateSessionTable } from "../store/actions/tableActions";
import Select from "react-select";

const Order = () => {
  const [errorMsg, setErrorMsg] = useState();
  const orders = useSelector((state) => state.order);
  const orderInfo = useSelector((state) => state.table.orderInfo);
  const sessionId = useSelector((state) => state.table.sessionId);
  const floorPlans = useSelector((state) => state.table.floorPlans);
  const updateTableLoader = useSelector((state) => state.table.updateTableLoader);
  const page_theme = useSelector((state) => state.table?.business_Info?.page_layout?.instore?.cart);
  const text_modals = useSelector((state) => state?.table?.modalText);
  const orderPopData = text_modals?.filter((updated) => "order".includes(updated?.modal_type))[0];
  const uupspage_theme = useSelector(
    (state) => state.table?.business_Info?.page_layout?.instore?.menu
  );

  const payload = {
    businessLocationId: orderInfo?.businessLocationId,
    sessionId: sessionId
  };
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [showFinal, setShowFinal] = useState(false);
  // const [setPrepTime] = useState("00:00:00");
  const [showPendingpayment, setShowPendingpayment] = useState(false);
  // const [images] = useState(["pasta", "antipasti", "pinsa", "bibite", "insalata", "risotto"]);

  const takeAway = useSelector((state) => state.menu.takeAway);
  const [errorMoadal, setErrorMoadal] = useState(false);
  const [takeAwayModal, setTakeAwayModal] = useState(false);
  const [takeAwayValue, setTakeAwayValue] = useState("00:00:00");
  const [tableNameModal, setTableNameModal] = useState(false);
  const [selectedTable, setSelectedTable] = useState("");
  const store = useSelector((state) => state.table.business_Info);
  const [floorPlanData, setFloorPlanData] = useState([]);
  useEffect(() => {
    if (floorPlans) {
      let floorPlanArray = [{ value: "", label: "Select" }];
      floorPlans.forEach((_item) => {
        if (![1, 2, 3, 4].includes(_item?.number)) {
          floorPlanArray.push({ value: _item?.number, label: _item?.number });
        }
      });
      setFloorPlanData([...floorPlanArray]);
    }
  }, [floorPlans]);

  useEffect(() => {
    if (orderInfo?.tableNumber != 1) {
      setTableNameModal(false)
    }
  }, [orderInfo?.tableNumber])
  const takeAwayInputEvent = (event) => {
    setTakeAwayValue(event.target.value);
  };
  const NetworkError = () => {
    setErrorMsg("You Are Offline!");
    setErrorMoadal(true);
  };
  const showTakeawayMOdal = () => {
    if (takeAway === "true") {
      setShowFinal(false);
      setTakeAwayModal(true);
    } else if (takeAway === "false") {
      setTakeAwayModal(false);
    }
  };

  useEffect(() => {
    if (orders?.loading) {
      dispatch(SetLoading(false));
    }
    if (orders.orderSuccess === true) {
      dispatch(setOrderSuccess(false));
    }
    if (payload?.businessLocationId && payload?.sessionId) {
      dispatch(loadOrdersBySession(payload));
    }
  }, []);
  // useEffect(() => {

  // }, [payload]);

  // const calculateTimeToOrder = async () => {
  //   let PrepTimes = [];
  //   await orders?.Carts?.forEach((item) => {
  //     PrepTimes.push(item?.prepTime);
  //   });

  //   let calculatedTime = 0;

  //   calculatedTime = Math.max(...PrepTimes) || "0:00";
  //   setPrepTime(calculatedTime);
  // };

  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   arrows: false,
  //   speed: 500,
  //   slidesToShow: 6,
  //   slidesToScroll: 3,
  //   autoplay: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 2,
  //         infinite: true,
  //         dots: false,
  //         arrows: false
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //         dots: false,
  //         arrows: false
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //         dots: false,
  //         arrows: false
  //       }
  //     }
  //   ]
  // };

  const orderPlacement = {
    businessLocationId: orderInfo?.businessLocationId,
    cardId: orderInfo.cardId,
    thirdPartyReference: uuidv4(),
    orderNote: "Test Order",
    orderType: null,
    sessionID: sessionId,
    customerInfo: {
      firstName: "Test",
      lastName: "Doe",
      thirdPartyReference: "ZEN-7074522d-c71d-403f",
      email: "test@gmail.com",
      contactNumberAsE164: "012345678",
      notes: "nothing",
      salutation: "null"
    },
    tableNumber: orderInfo?.tableNumber,
    items: []
  };

  useEffect(() => {
    // calculateTimeToOrder();
    window.scrollTo({ top: 110 });
  }, []);
  const createOrder = (data) => {
    if (takeAway === "true") {
      showTakeawayMOdal();
    } else {
      if (orders?.Carts?.items?.length === 0 || !data?.tableNumber || !data?.businessLocationId) {
        setErrorMsg("Make sure you have: 1- Add items to cart 2- Joined a table");
        setErrorMoadal(true);
        return;
      }
      Object.assign(data, {
        orderType: "served"
      });
      dispatch(SetLoading(true));
      dispatch(postOrder(data, orders.Carts)).then(() => {
        setTimeout(() => {
          navigate("/bill");
        }, 5000);
      });
      setShowFinal(true);
    }
  };
  // useEffect(() => {
  //   if (orders?.orderSuccess === "pending" ) {
  //     setShowFinal(true);
  //   }
  // }, [orders?.orderSuccess]);

  // useEffect(() => {
  //   if (orders?.ordersPlaced.length > 0) {
  //     setShowFinal(true);
  //   }
  // }, [orders?.ordersPlaced]);

  return (
    <>
      <div
        style={{
          height: "100vh"
        }}>
        <div
          style={{
            paddingBottom: "100px",
            marginBottom: "40px"
          }}
          className=" mt-4 pt-4">
          <div className="logo " style={{ backgroundColor: store?.body_bgcolor }}>
            <div className="mainLogo">
              <img
                src={store.business_logo}
                alt="main_logo"
                width="85%"
                style={{ maxWidth: "280px", verticalAlign: "middle" }}
              />
            </div>
          </div>
          <Stack direction="horizontal" gap={5} className="mt-4 px-3">
            <h1 className="orders-title text-uppercase">
              <b
                style={{
                  color: page_theme?.primary_order_header_text_color,
                  fontSize: page_theme?.font_size_order_header,
                  fontFamily: page_theme?.font_family_order_headet_text,
                  fontWeight: "600"
                }}>
                Dein Warenkorb
              </b>
              <span style={{ color: "#ffffff" }}>*</span>
            </h1>
            <img
              src={page_theme?.right_icon_url}
              className="img-fluid ms-auto"
              width={120}
              height={61}
              alt="order"
            />
          </Stack>
          <Sortable showFinal={showFinal} page_theme={page_theme} />

          {/* <PreviousOrders /> */}
          <PreviousOrderDesing />

          <ModalInterface
            display={showFinal}
            handleClose={() => {
              setShowFinal(false);
            }}
            bodyStyles={{
              backgroundColor: page_theme?.primary_cart_logo_bgcolor
            }}
            center={true}
            closeEvent={() => setShowFinal(false)}
            // title={
            //   <div
            //     className="closeBtnDivide"
            //     data-bs-dismiss="modal"
            //     style={{
            //       backgroundColor: page_theme?.cart_modal_cross_icon_bgColor
            //     }}
            //     onClick={() => setShowFinal(false)}>
            //     <i className="fas fa-times"></i>
            //   </div>
            // }
            content={
              <>
                <div className="text-center">
                  <div
                    className="text-center text-uppercase thankuText mt-3"
                    style={{
                      color: page_theme?.primary_cart_modal_header_text_color
                    }}>
                    {orderPopData?.heading_text || "DANKE"}
                  </div>
                  <div
                    className="text-center forOrderText pt-3"
                    style={{
                      color: page_theme?.primary_cart_modal_second_text_color,
                      fontFamily: "sans-serif"
                    }}>
                    {" "}
                    {orderPopData?.primary_text || "wir haben deine Bestellung erhalten"}
                  </div>
                  <img
                    // src={page_theme?.right_icon_url}
                    src="/img/bestselling.png"
                    className="bestselling-img img-fluid ms-auto mt-2"
                    alt="order"
                  />
                </div>
                <h3
                  className="text-uppercase orderModalText mt-3"
                  style={{
                    color: page_theme?.primary_cart_modal_third_text_color
                  }}>
                  {orderPopData?.secondary_text ||
                    "DIE VAPIANISTI MACHEN SICH GLEICH AN DIE ARBEIT..."}
                </h3>
                <div className="text-center divideCalc3 ">
                  {/* <Slider {...settings}>
                  {images.map((url) => (
                    <i key={uuidv4()}>
                      <img
                        src={`./img/placeorder/${url}.png`}
                        alt="carousel"
                        height={60}
                        width={60}
                      />
                    </i>
                  ))}
                </Slider> */}
                  {/* <div>
                  <h3>
                    DU KANNST JEDER MOMENT BEIM KELLNER ODER ONLINE BEZAHLEN INDEM DU AUF
                    „BESTELLUNG“ CLICKST.
                  </h3>
                </div> */}
                </div>
              </>
            }
          />
          <div className="pay-sec d-flex justify-content-center align-items-center p-2">
            {orders?.Carts?.length === 0 ? (
              <Button
                variant="col-2"
                style={{
                  borderRadius: "30px",
                  border: "none",
                  width: "165px",
                  color: page_theme?.primary_place_order_btn_text_color,
                  backgroundColor: page_theme?.primary_place_order_btn_bgcolor,
                  // fontSize: "16px",
                  opacity: 1
                }}
                className={`font-${page_theme?.font_family_primary_place_order_btn} fs-${page_theme?.font_size_primary_place_order_btn} dark-green`}
                disabled>
                BESTELLUNG AUFGEBEN
              </Button>
            ) : (
              <div className="pay-sec d-flex justify-content-center align-items-center p-2 ">
                <Button
                  variant="text-light col-2"
                  style={{
                    borderRadius: "30px",
                    border: "none",
                    width: "165px",
                    color: page_theme?.primary_place_order_btn_text_color,
                    backgroundColor: page_theme?.primary_place_order_btn_bgcolor,
                    // fontSize: "16px",
                    opacity: 1
                  }}
                  disabled={orders.loading}
                  className={`font-${page_theme?.font_family_primary_place_order_btn} fs-${page_theme?.font_size_primary_place_order_btn}`}
                  onClick={() => {
                    if (
                      orders?.ordersPlaced[0]?.payment_status === "paid"
                      // ||
                      // orders?.ordersPlaced[0]?.payment_status === "partially_paid"
                    ) {
                      setShowPendingpayment(true);
                      return;
                    }
                    if (orderInfo?.businessLocationId.length > 0 && sessionId.length > 0) {
                      if (!window?.navigator?.onLine) {
                        // alert("Network Error You are offline !");
                        NetworkError();
                        return;
                      }

                      if (orders?.Carts?.length > 0) {
                        if (
                          // orders?.ordersPlaced?.length === 0 ||
                          // orders.ordersPlaced[0]?.salesEntries?.length === 0 ||
                          orderInfo?.tableNumber == "1"
                        ) {
                          setTableNameModal(true);
                        } else {
                          createOrder(orderPlacement);
                        }
                      }
                    } else {
                      setErrorMsg("You don`t have required things!");
                      setErrorMoadal(true);
                    }

                    // } else {
                    //   setShowAlert(true);
                    // }
                  }}>
                  {orders.loading ? (
                    <span
                      className="spinner-border spinner-border-lg text-danger "
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    <span
                      className=""
                      style={{
                        color: page_theme?.primary_place_order_btn_text_color,
                        backgroundColor: page_theme?.primary_place_order_btn_bgcolor
                      }}>
                      BESTELLUNG AUFGEBEN
                    </span>
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalInterface
        display={takeAwayModal}
        handleClose={() => {
          setTakeAwayModal(false);
        }}
        title={<></>}
        closeEvent={() => setTakeAwayModal(false)}
        content={
          <>
            <div
              className="closeBtnDivide"
              style={{
                backgroundColor: "red"
              }}
              data-bs-dismiss="modal"
              onClick={() => setShowFinal(false)}>
              <i className="fas fa-times"></i>
            </div>
            <div className="text-center ">
              <div className="text-center text-uppercase thankuText">Take Away</div>
            </div>
            <div className="divideCalc3">
              <div className="order-description">
                <Form.Label>Take Away Time</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="#"
                  value={takeAwayValue}
                  onChange={takeAwayInputEvent}
                />
              </div>
              <div className="divideBottomBtn">
                <button
                  onClick={() => {
                    // {
                    //   ...orderPlacement,
                    //   takeaway_time: takeAwayValue,
                    // }
                    if (
                      orders?.Carts?.items?.length === 0 ||
                      !orderPlacement?.tableNumber ||
                      !orderPlacement?.businessLocationId
                    ) {
                      setErrorMsg("Make sure you have: 1- Add items to cart 2- Joined a table");
                      setErrorMoadal(true);
                      return;
                    }
                    Object.assign(orderPlacement, {
                      takeaway_time: takeAwayValue,
                      orderType: "pickup"
                    });
                    dispatch(postOrder(orderPlacement, orders.Carts)).then(() => {
                      setTimeout(() => {
                        navigate("/bill");
                      }, 5000);
                    });
                  }}
                  style={{ width: "50%", margin: "auto" }}>
                  Takeaway
                </button>
              </div>
            </div>
          </>
        }
      />
      <ModalInterface
        display={showPendingpayment}
        center="true"
        size={"sm"}
        handleClose={() => {
          setShowPendingpayment(false);
        }}
        closeEvent={() => setShowPendingpayment(false)}
        title={
          <>
            {" "}
            <div className="text-center d-flex justify-content-end">
              <div
                className=" text-white close-btn d-flex align-items-center justify-content-center p-3 mt-1 me-1"
                style={{
                  border: "none",
                  borderRadius: "50px",
                  backgroundColor: page_theme?.cart_modal_cross_icon_bgColor,
                  width: "30px",
                  height: "30px",
                  color: "white",
                  fontSize: "30px"
                }}
                role="button">
                <i
                  className="fa fa-close fs-5"
                  onClick={() => {
                    setShowPendingpayment(false);
                  }}></i>
              </div>
            </div>
          </>
        }
        content={
          <>
            <div className="text-center divideCalc3 mb-2">
              <h5
                className=" "
                style={{
                  color: uupspage_theme?.primary_uups_header_text_color,
                  fontWeight: "500",
                  fontSize: "50px",
                  fontFamily: "Source Sans Pro-Bold"
                }}>
                UUPS{" "}
              </h5>
              <h5
                style={{
                  fontSize: "25px"
                }}>
                ES SCHEINT ALS HÄTTEST<br></br> DU NOCHKEINE BESTELLUNG
                <br /> ABGESCHICKT
              </h5>
            </div>
          </>
        }
      />
      <ModalInterface
        display={tableNameModal}
        center="true"
        handleClose={() => {
          setTableNameModal(false);
        }}
        title={<div className="mt-3 text-center">An welchem Tisch sitzt du?</div>}
        closeEvent={() => setTableNameModal(false)}
        size="lg"
        content={
          <>
            <Form>
              <Form.Group className="mb-3">
                <Select
                  isClearable={false}
                  isSearchable={true}
                  className="basic-single"
                  classNamePrefix="select"
                  name="floorPlans"
                  placeholder="Tischnummer..."
                  options={floorPlanData}
                  onChange={(e) => {
                    setSelectedTable(e.value);
                  }}
                />
              </Form.Group>
            </Form>
            <div className="d-flex justify-content-end">
              <Button
                disabled={updateTableLoader}
                style={{
                  borderRadius: "30px",
                  border: "none",
                  width: "150px",
                  color: page_theme?.primary_place_order_btn_bgcolor,
                  // backgroundColor: page_theme?.primary_place_order_btn_text_color
                  backgroundColor: "#AD9F85",
                  // fontSize: "16px",
                }}
                className={`mx-3 font-${page_theme?.font_family_primary_place_order_btn} fs-${page_theme?.font_size_primary_place_order_btn}`}
                onClick={() => {
                  if (!selectedTable) {
                    setErrorMoadal(true);
                    setErrorMsg("Bitte wähle einen Tisch.");
                    return;
                  }
                  dispatch(updateSessionTable({ id: sessionId, table_id: selectedTable })).then(
                    (res) => {
                      if (!res?.success) {
                        setErrorMoadal(true);
                        setErrorMsg(res?.response?.data?.message);
                        return;
                      }
                      setSelectedTable("");
                      setTableNameModal(false);
                      orderPlacement.tableNumber = res?.data?.table_id;
                      createOrder(orderPlacement);
                    }
                  );
                }}>
                {updateTableLoader ? (
                  <span
                    className="spinner-border spinner-border-lg text-danger text-center"
                    role="status"
                    aria-hidden="true"></span>
                ) : (
                  <span> Bestätigen</span>
                )}
              </Button>
              <Button
                onClick={() => setTableNameModal(false)}
                style={{
                  borderRadius: "30px",
                  border: "none",
                  width: "150px",
                  color: page_theme?.primary_place_order_btn_bgcolor,
                  // backgroundColor: page_theme?.primary_place_order_btn_text_color
                  backgroundColor: "#634E4D",
                  // fontSize: "16px",
                }}>
                Abbrechen
              </Button>
            </div>
          </>
        }
      />
      <ModalInterface
        display={errorMoadal}
        center="true"
        handleClose={() => {
          setErrorMoadal(false);
        }}
        title={<></>}
        closeEvent={() => setErrorMoadal(false)}
        content={
          <>
            <div className="text-center divideCalc3 mb-2">
              <div className="text-center divideCalc3 mb-2">
                <h1> {errorMsg}</h1>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default Order;
