import Repository from "./Repository";
const description = "/articles/article-description";
// const singleIngredients = "/menus/single-menu";
const singleIngredients = "/menu_v2/single-menu";

export default {
  getDescription(payload) {
    return Repository.get(`${description}/${payload?.sku}/?blId=${payload?.blId}`);
  },

  getIngredients(payload) {
    return Repository.get(`${singleIngredients}/${payload?.sku}/?blId=${payload?.blId}`);
  }
};
