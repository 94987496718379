import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { v4 as uuidv4 } from "uuid";

const Hero = ({ specials, setCartData, setShowCart, data }) => {
  const settings = {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const promotions = useSelector((state) => state.promotions.promotion);

  useEffect(() => {
    setHeaderPromotion(promotions);
  }, []);

  const [headerPromotions, setHeaderPromotion] = useState(promotions);
  // let img = [];

  return (
    <>
      {specials !== null ? (
        <Slider {...settings}>
          {specials?.map((prom) => {
            return prom?.news?.map((url) => {
              return (
                <div key={uuidv4()}>
                  <a href={url?.url} target="_blank" rel="noreferrer" key={uuidv4()}>
                    <img
                      src={url?.image}
                      className="img-responsive img-fluid"
                      alt="menu-item"
                      onClick={() => {
                        setCartData(data);
                        setShowCart(true);
                      }}
                    />
                  </a>
                </div>
              );
            });
          })}
        </Slider>
      ) : (
        <Row className="m-0">
          <Col md="12" lg="8" sm="12" xs="12" className="p-0">
            {" "}
            <Slider {...settings}>
              {headerPromotions?.map((prom) => {
                return prom?.header?.map((url) => {
                  return (
                    <a href={url?.url} target="_blank" rel="noreferrer" key={uuidv4()}>
                      <img className="img-fluid" src={url?.image} alt="Promotions" />
                    </a>
                  );
                });
              })}
            </Slider>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Hero;
