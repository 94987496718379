import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

import { Navigation, Pagination, Mousewheel, Keyboard, EffectCoverflow } from 'swiper/modules';
// import "../../assets/css/arrow.css";
import { v4 as uuidv4 } from 'uuid';
import OrderDetails from "../order/orderdetails";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Sync_Carts } from "../../store/actions/orderAction";
import { getSingleIngredients, loadDescription, SetCartData as SetCartDataAction } from "../../store/actions/menuAction";
import { trimItemName } from "../../common/TrimStrings";

const SpecialSection = ({
                            setShowCart,

                            setInfoAllergens,
                            setAllergenData,
                            swiping,
                            specialsItem,
                            page_theme,
                            categoryIs
                        }) => {

    const dispatch = useDispatch();
    const busId = useSelector((state) => state?.table?.orderInfo?.businessLocationId);
    var testImg = ["./img/PlatenKnife.png"];

    if (!(categoryIs.special === true && specialsItem?.special.length > 0)) {
        return null;
    }

    return (
        <Row className="mt-3 w-100 gx-0 gy-0" id="Special">
            <Col md="12" lg="12" sm="12" xs="12">
                <div className="sectionHdng">
                    <div className={`hdngflex font-${page_theme?.font_family_summer_specials_title} fs-${page_theme?.font_size_summer_specials_title}`}>
                        <img src={page_theme?.right_icon_url} alt="specials" width="140px" />
                    </div>
                    <div className="hdnglineyellow" style={{ backgroundColor: page_theme?.primary_line_summer_specials_color }}></div>
                </div>
            </Col>

            <Col md="12" sm="12" lg="12" xs="12" >
                <Swiper
                    cssMode={true}
                    navigation={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard,EffectCoverflow ]}
                    className="mySwiper"
                    slidesPerView={2.5}
                    spaceBetween={0}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 0,
                    }}
                >
                    {specialsItem?.special.map((data) => (
                        <SwiperSlide key={uuidv4()}  style={{
                            cursor: "pointer"
                        }}
                                     className="dish-wrapper">
                            <h1 className={`menu-itemName d-flex justify-content-center mb-2 font-${page_theme?.font_family_menu_item_title}  mb-2 ps-1 pe-1 fs-${page_theme?.font_size_menu_item_title}`}
                                style={{ color: page_theme?.primary_menu_item_title_color }}>
                                {window.innerWidth <= 500 && window.innerHeight <= 900
                                    ? trimItemName(data?.name)
                                    : data?.name}
                            </h1>

                            <div className="p-1 dish-img-container">
                                <img style={{ cursor: "pointer" }} src={data?.image || testImg[0]} className="dish-img1" alt="menu-item"
                                     onClick={() => {
                                         if (!swiping) {
                                             dispatch(SetCartDataAction(data));
                                             dispatch(loadDescription({ sku: data.sku, blId: busId }));
                                             dispatch(Sync_Carts(data));
                                             setShowCart(true);
                                             dispatch(getSingleIngredients({ sku: data.sku, blId: busId }));
                                         }
                                     }}
                                />

                                {data.isBestSeller === true ? (
                                    <img src="/img/hearticon.png" className="heartImg" alt="hearticon" onClick={() => { setInfoAllergens(true); }} />
                                ) : (
                                    <img src="/img/hearticon.png" className="heartImg invisible" alt="hearticon" />
                                )}
                            </div>

                            <OrderDetails page_theme={page_theme} data={data} setInfoAllergens={setInfoAllergens} setAllergenData={setAllergenData} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Col>
        </Row>
    );
};

export default SpecialSection;
