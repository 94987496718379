import React, { useEffect } from "react";
import { Canvas } from "../components/guestBook/Canvas";
import { Container, Row, Stack } from "react-bootstrap";
import { WallOfFame } from "../components/guestBook/WallOfFame";
import { useSelector, useDispatch } from "react-redux";
import { checkIsGuestBook } from "../store/actions/guestBookAction";
import { useNavigate } from "react-router-dom";

const GuestBook = () => {
  const page_theme = useSelector(
    (state) => state.table?.business_Info?.page_layout?.instore?.guest_wall
  );
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const isGuestBook = useSelector((state) => state.table.isGuestBook);
  const store = useSelector((state) => state.table.business_Info);
  useEffect(() => {
    dispatch(checkIsGuestBook());
    // .then((resp) => {
    //   if (resp?.success) {
    //     setIsGuestBookAllowed(resp?.data?.guest_book);
    //     if (!resp?.data?.guest_book) {
    //       navigate("/home");
    //     }
    //   }
    // });
  }, []);
  useEffect(() => {
    if (!isGuestBook) {
      navigate("/home");
    }
  }, [isGuestBook]);
  return (
    <>
      <Container className=" mt-5 pt-4 ">
        <Row className=" mt-3  ">
          {!isGuestBook ? (
            <div className=" min-vh-100 w-100">
              <span
                className="spinner-border spinner-border-lg text-danger text-center position-absolute top-50 start-50"
                role="status"
                aria-hidden="true"></span>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-center mt-2 mainLogo">
                <img src={store.business_logo} alt="logo" className=" img-responsive" width={300} />
              </div>
              <Stack
                className="justify-content-between align-items-center d-flex mb-2 mt-1"
                direction="horizontal">
                <div style={{ color: "#33ab53" }} className="hdngflex">
                  <h1 className=" text-uppercase">
                    <b
                      style={{
                        color: page_theme?.primary_guestbook_entry_text_color,
                        fontWeight: "600",
                        fontFamily: page_theme?.font_family_primary_wall_of_fame_title,
                        fontSize: page_theme?.font_size_wall_of_fame_title
                      }}>
                      GUEST BOOK <span style={{ color: "#ffffff" }}>*</span>
                    </b>
                  </h1>
                </div>
                <div>
                  <img
                    src={page_theme?.right_icon_url}
                    className="img-fluid ms-auto"
                    alt="Icon"
                    width={85}
                    height={85}
                  />
                </div>
              </Stack>
              <Container className="boardDrawing ">
                <Canvas page_theme={page_theme} />

                {/* <hr /> */}
              </Container>
              <div className="p-4">
                <WallOfFame page_theme={page_theme} />
              </div>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default GuestBook;
