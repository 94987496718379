import React, { useState } from "react";
import { Card, Col, Collapse, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const PreviousOrderDesign = () => {
  const [expand, setExpand] = useState(-1);
  const { ordersPlaced } = useSelector((state) => state?.order);
  var total = 0;
  return (
    <>
      <Row className="p-4">
        <div className="card ">
          <div className="p-2">
            <h3>Alle bisher von deiner Gruppe getätigten Bestellungen </h3>
          </div>
          <Row className="p-2">
            <Col lg="12" md="12" sm="12" xs="12">
              <div className="headings d-flex justify-content-between">
                <Col lg="2" md="2" sm="2" xs="2" className="h5 ">
                  <div className="d-flex justify-content-center w-100">Name</div>
                </Col>
                <Col lg="2" md="2" sm="2" xs="2" className="h5 ">
                  <div className="d-flex justify-content-center w-100">Preis</div>
                </Col>
                <Col lg="2" md="2" sm="2" xs="2" className="h5 ">
                  <div className="d-flex justify-content-center w-100">Menge</div>
                </Col>
                <Col lg="2" md="2" sm="2" xs="2" className="h5 ">
                  <div className="d-flex justify-content-center w-100">Gesamt</div>
                </Col>
                <Col lg="2" md="2" sm="2" xs="2" className="h5 ">
                  <div className="d-flex justify-content-center w-100">Action</div>
                </Col>
              </div>
            </Col>
            {ordersPlaced[0]?.salesEntries?.map((item, index) => {
              total = total + item?.itemPrice;
              item?.greenItems?.map((gprice) => {
                return total + gprice?.itemPrice;
              });
              item?.redItems?.map((rprice) => {
                return total - rprice?.itemPrice;
              });
              return (
                <>
                  <div
                    className="headings d-flex justify-content-between"
                    onClick={() => {
                      setExpand(expand === index ? -1 : index);
                    }}>
                    <Col lg="2" md="2" sm="2" xs="2">
                      <div className="d-flex justify-content-center w-100 ">{item?.itemName}</div>
                    </Col>
                    <Col lg="2" md="2" sm="2" xs="2">
                      <div className="d-flex justify-content-center w-100 ">
                        {" "}
                        {"  "}€ {Number(item?.itemPrice).toFixed(2)}
                      </div>
                    </Col>
                    <Col lg="2" md="2" sm="2" xs="2">
                      <div className=" d-flex justify-content-center w-100 ">
                        {item?.quantity} x
                      </div>{" "}
                    </Col>
                    <Col lg="2" md="2" sm="2" xs="2">
                      <div className="d-flex justify-content-center w-100">
                        {" "}
                        {"  "}€{" "}
                        {/* {(
                          Number(item?.itemPrice) * Number(item?.quantity)
                        ).toFixed(2)} */}
                        {(Number(total) * Number(item?.quantity)).toFixed(2)}
                      </div>{" "}
                    </Col>
                    <Col lg="2" md="2" sm="2" xs="2">
                      <div className="d-flex justify-content-center w-100">
                        {" "}
                        <img
                          src={index === expand ? `/img/icons/up.png` : `/img/icons/down.png`}
                          height={20}
                          alt="icon"
                        />
                      </div>
                    </Col>
                  </div>
                  <Collapse in={index === expand}>
                    <Card className="headings">
                      <Row className="p-3">
                        <Col md="6" lg="6" sm="12" xs="12" className="invisible">
                          abcde
                        </Col>
                        <Col md="6" lg="6" sm="12" xs="12">
                          <Row className=" p-2">
                            <Col md="6" lg="6" sm="12" xs="12">
                              <div className="border-bottom">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <b>Extra Zutaten</b>
                                  </div>
                                  <div>
                                    {" "}
                                    <b>Price</b>
                                  </div>
                                </div>
                              </div>
                              {item?.greenItems?.map((gitems) => {
                                return (
                                  <div className="d-flex justify-content-between" key={uuidv4()}>
                                    <div>
                                      <img
                                        src="/img/icons/PLUS_BLACK.png"
                                        height={10}
                                        className="mx-2"
                                        alt="icon"
                                      />
                                      <span>{gitems?.itemName}</span>
                                    </div>
                                    <div>€ {gitems?.itemPrice}</div>
                                  </div>
                                );
                              })}
                            </Col>
                            <Col md="6" lg="6" sm="12" xs="12">
                              <div className="border-bottom">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <b>Removed Ingredients</b>
                                  </div>
                                  <div>
                                    {" "}
                                    <b>Price</b>
                                  </div>
                                </div>
                              </div>
                              {item?.redItems?.map((rditem) => {
                                <div className="d-flex justify-content-between" key={uuidv4()}>
                                  <img
                                    src="/img/icons/PLUS_BLACK.png"
                                    height={10}
                                    className="mx-2"
                                    alt="icon"
                                  />
                                  <span>{rditem?.itemName}</span>
                                  <div>€ {rditem?.itemPrice}</div>
                                </div>;
                              })}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Collapse>
                </>
              );
            })}
          </Row>
        </div>
      </Row>
    </>
  );
};

export default PreviousOrderDesign;
