import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { trimItemName } from "../../common/TrimStrings";
import { Sync_Carts } from "../../store/actions/orderAction";
import { v4 as uuidv4 } from "uuid";
import {
  getSingleIngredients,
  loadDescription,
  SetCartData as SetCartDataAction
} from "../../store/actions/menuAction";
import OrderDetails from "../order/orderdetails";
// import ScrollContainer from "react-indiana-drag-scroll";
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Navigation, Pagination, Mousewheel, Keyboard, EffectCoverflow} from 'swiper/modules';
const BestSeller = ({
  setShowCart,
  id,
  setInfoAllergens,
  setAllergenData,
  swiping,
  bestSellerData,
  page_theme
}) => {
  const dispatch = useDispatch();
  console.log(  JSON.stringify(bestSellerData, null, 2))

  // const [testImg] = useState(["./img/PlatenKnife.png"]);
  var testImg = ["./img/PlatenKnife.png"];

  const busId = useSelector((state) => state?.table?.orderInfo?.businessLocationId);

  return (
    <>
      {/*{/* Best Seller start */}
      <Row className="w-100 gx-0 gy-0" id={id}>
        <Col md="12" sm="12" lg="12" xs="12">
          <div className="sectionHdng">
            <div
              className="hdngflex"
              style={{ color: page_theme?.primary_title_best_seller_color }}>
              <h1
                style={{ color: page_theme?.primary_title_best_seller_color }}
                className={`font-${page_theme?.font_family_best_seller_title} fs-${page_theme?.font_size_best_seller_title}`}>
                BEST SELLERS{" "}
              </h1>
            </div>
            <div
              className="hdnglineblue"
              style={{
                backgroundColor: page_theme?.primary_line_best_seller_color
              }}></div>
          </div>
        </Col>
        <Col md="12" sm="12" lg="12" xs="12" style={{  position: 'relative'}}>
          {bestSellerData?.length > 0 && (
              <Swiper
                  cssMode={true}
                  navigation={true}
                  mousewheel={true}
                  keyboard={true}
                  modules={[Navigation, Pagination, Mousewheel, Keyboard,EffectCoverflow ]}
                  className="mySwiper"
                  slidesPerView={2.5}
                  spaceBetween={0}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 0,
                  }}
              >

              {bestSellerData?.map((data) => {
                return (
                    <SwiperSlide key={uuidv4()}
                                 style={{
                                   cursor: "pointer"
                                 }}
                                 className="dish-wrapper">

                    <h5
                      className={`menu-itemName d-flex justify-content-center mb-2 fs-${page_theme?.font_size_menu_item_title} font-${page_theme?.font_family_menu_item_title}`}
                      style={{
                        color: page_theme?.primary_menu_item_title_color
                      }}>
                      {window.innerWidth <= 500 && window.innerHeight <= 900
                        ? trimItemName(data?.name)
                        : data?.name}
                    </h5>
                    <div className="p-1 dish-img-container">
                      <img
                        style={{ cursor: "pointer" }}
                        src={data?.image || testImg[0]}
                        className=" dish-img1"
                        alt="menu-item"
                        onClick={() => {
                          if (!swiping) {
                            dispatch(
                              SetCartDataAction(
                                data
                                //  ingredients: stableIngredients
                              )
                            );

                            dispatch(
                              loadDescription({
                                sku: data.sku,
                                blId: busId
                              })
                            );

                            dispatch(Sync_Carts(data));
                            setShowCart(true);
                            dispatch(
                              getSingleIngredients({
                                sku: data.sku,
                                blId: busId
                              })
                            );
                          }
                        }}
                      />

                      {data.isBestSeller === true ? (
                        <img
                          src="/img/hearticon.png"
                          className="heartImg"
                          alt="hearticon"
                          onClick={() => {
                            setInfoAllergens(true);
                          }}
                        />
                      ) : (
                        <img
                          src="/img/hearticon.png"
                          className="heartImg invisible"
                          alt="hearticon"
                        />
                      )}
                    </div>

                    <OrderDetails
                      page_theme={page_theme}
                      data={data}
                      setInfoAllergens={setInfoAllergens}
                      setAllergenData={setAllergenData}
                    />
                    </SwiperSlide>
                );
              })}

              </Swiper>
          )}
        </Col>
      </Row>

      {/* Best Seller Fin */}
    </>
  );
};

export default BestSeller;
