
const getOffset = (el) => {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  };
export const handleSetActive = (targetIndex, _scrollWidth,category) => {
    let navbarMenu = document.getElementById("navbar-menu");
    let navLinkMenu = document.getElementsByClassName("nav-link-menu");
    let hasHScroll = _scrollWidth > navbarMenu.clientWidth;

    if (hasHScroll) {
        if(category !== "Special" &&
            category !== "Best Seller" &&
            category !== "VEGAN"){
            targetIndex = targetIndex - 3
        }
        let targetNavLink = navLinkMenu[targetIndex];
        if (targetNavLink) {
            navbarMenu.scrollLeft = getOffset(targetNavLink).left;
        }
    }
};