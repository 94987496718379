import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const SearchBar = () => {
  const businessInfo = useSelector((state) => state?.table?.business_Info?.business_name);
  const loadingScreen = useSelector((state) => state.table.loading);

  let [queryValue] = useSearchParams();
  let table_no = queryValue.get("table_no");

  return (
    <>
      {!loadingScreen && (
        <Navbar sticky="top" className="bg-danger justify-content-center p-1 align-items-center">
          <Nav.Item>
            <span className="text-white text-uppercase">
              {businessInfo}.- {table_no}
            </span>
          </Nav.Item>
          <Nav.Item>
            <span>
              <div
                style={{
                  height: "20px",
                  width: "20px",
                  marginLeft: "17px",
                  marginBottom: "12px"
                }}>
                <img src="./img/location.png " className="w-100 h-100" alt="loc"></img>
              </div>
            </span>
          </Nav.Item>
        </Navbar>
      )}
    </>
  );
};

export default SearchBar;
