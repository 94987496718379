import axios from "axios";
import { SET_GUESTBOOK } from "../types";

export const checkIsGuestBook = () => async (dispatch) => {
  try {
    let res = await axios.get(`${process.env.REACT_APP_BackendURL}/guest-book`);
    if (res?.data?.success) {
      dispatch({
        type: SET_GUESTBOOK,
        payload: res?.data?.data?.guest_book
      });
    }
  } catch (err) {
    alert("Something went wrong (Guest Book ) try again" + err);
  }
};
