import { Badge, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectIngGroup } from "../../../store/actions/menuAction";
import { AddIngredients, DeleteIngredients } from "../../../store/actions/orderAction";
import { v4 as uuidv4 } from "uuid";

const IndividualItems = (cartData) => {
  let combinedItems = [];
  cartData?.ingredients
    ?.filter((itp) => itp.group != null || itp.group != undefined)
    ?.forEach((itex) => {
      let findOld = combinedItems?.find((ib) => ib?.group == itex?.group);
      let findOldIndex = combinedItems?.findIndex((ib) => ib.group == itex?.group);
      let isSingleOnly = cartData?.single_only?.includes(itex?.group_id);
      // itex = { ...itex, isSingleOnly: isSingleOnly }
      itex["isSingleOnly"] = isSingleOnly;

      if (findOld == null) {
        combinedItems.push({ group: itex?.group, items: [itex] });
      } else {
        if (findOldIndex >= 0) {
          combinedItems[findOldIndex] = {
            ...combinedItems[findOldIndex],
            items: [...combinedItems[findOldIndex].items, itex],
            group: itex?.group
          };
        }
      }
    });

  return combinedItems;
};
const CartBody = ({ page_theme }) => {
  const dispatch = useDispatch();
  const loadingIngredients = useSelector((state) => state.bestSeller.loading);
  const { ingredientsLoading, cartData } = useSelector((state) => state.menu);
  console.log(page_theme?.primiary_popup_add_to_cart_select_menu_check_color, "the color");

  if (!loadingIngredients || ingredientsLoading) {
    return IndividualItems(cartData).map((itx, index) => (
      <div key={uuidv4()}>
        <section className=" " id={`IngGroup${index}`}>
          <h1
            id={index}
            className=""
            style={{
              fontSize: "20px",
              fontWeight: 700,
              marginTop: "27px"
            }}>
            {itx?.group}
          </h1>
        </section>
        <ListGroup variant="flush" className="p-2">
          {itx?.items
            .filter(
              (v, i, a) => a.findIndex((v2) => ["sku", "group"].every((k) => v2[k] === v[k])) === i
            )
            .map((item) => (
              <div key={uuidv4()}>
                <ListGroup.Item
                  className="d-flex justify-content-between align-items-start"
                  key={uuidv4()}
                  style={{
                    borderBottom: "2px solid #2F3E3F",
                    display: "flex",
                    justifyContent: "space-between"
                  }}>
                  <div className="ms-2 me-auto">
                    <h5 className="fw-bold" style={{ fontSize: "17px" }}>
                      {item?.ingredient_name}
                    </h5>
                  </div>
                  {item.price > 0 && (
                    <span
                      className="pe-2"
                      style={{
                        fontSize: "18px",
                        fontWeight: "400"
                      }}>
                      € {item.price}
                    </span>
                  )}

                  {!item.selected === true ? (
                    <Badge
                      className="extras cart-extrasplus"
                      bg=""
                      style={{
                        backgroundColor:
                          page_theme?.primiary_popup_add_to_cart_select_menu_check_color ||
                          "#e74c3c"
                      }}
                      onClick={() => {
                        let tempObj = cartData;
                        let realIndex = tempObj?.ingredients?.findIndex(
                          (item_) => item_?.sku === item?.sku
                        );

                        //deselect single_only ingredient of same group from selection
                        if (item?.isSingleOnly) {
                          let selectedSameGroupIngrdients = tempObj.ingredients.filter(
                            (ing) => ing?.selected && ing.group_id == item?.group_id
                          );
                          //there will be only 1 existing selection, because at a time only 1 from the group can be selected
                          let index = tempObj?.ingredients?.findIndex(
                            (item_) => selectedSameGroupIngrdients[0]?.sku === item_?.sku
                          );
                          if (index !== -1) tempObj.ingredients[index].selected = false;
                        }
                        tempObj.ingredients[realIndex].selected = true;
                        // if(tempObj?.single_only?.includes(tempObj?.ingredients[realIndex]?.group_id)){
                        //   tempObj.ingredients[realIndex].isSingleOnly = true;
                        //  }

                        let tempIngs = tempObj?.ingredients
                          .filter((ito) => ito?.selected === true)
                          .map((itei) => {
                            return {
                              ...itei,
                              quantity: 1,
                              course: cartData.course
                            };
                          });

                        let group_id_ing = null;
                        let single_only = [];
                        if (Object.prototype.hasOwnProperty.call(item.hasOwnProperty, "group_id")) {
                          group_id_ing = item.group_id;
                        }
                        if (Object.prototype.hasOwnProperty.call(cartData, "single_only")) {
                          single_only = cartData.single_only;
                        }

                        dispatch(
                          AddIngredients({
                            singles_only: single_only,
                            groupID: item?.group_id,
                            subitemSku: item?.sku,
                            id: cartData?.id,
                            sku: item.sku,
                            quantity: 1,
                            course: cartData.course,
                            cartData: {
                              ...cartData,
                              subItems: tempIngs
                            },
                            subItemIng: tempObj?.ingredients?.filter((tr) => tr?.selected !== false)
                          })
                        );

                        dispatch(
                          selectIngGroup({
                            data: cartData,
                            singles_only: single_only,
                            group: item.group,
                            sku: item.sku,
                            groupID: group_id_ing
                          })
                        );
                      }}>
                      <span className="">
                        {" "}
                        <i className="fa-solid  text-dark"></i>{" "}
                      </span>
                    </Badge>
                  ) : (
                    <Badge
                      className="extras cart-extras"
                      bg=""
                      style={{
                        backgroundColor:
                          page_theme?.primiary_popup_add_to_cart_select_menu_check_color ||
                          "#e74c3c"
                      }}
                      onClick={() => {
                        let tempObj = cartData;
                        let realIndex = tempObj.ingredients.findIndex(
                          (item_) => item_.sku === item.sku
                        );
                        tempObj.ingredients[realIndex].selected = false;
                        dispatch(
                          DeleteIngredients({
                            id: cartData?.id,
                            subitemSku: item?.sku,
                            sku: cartData?.sku
                          })
                        );
                      }}>
                      <span className="">
                        {" "}
                        <i className="fas fa-check text-white"></i>{" "}
                      </span>
                    </Badge>
                  )}
                </ListGroup.Item>
              </div>
            ))}
        </ListGroup>
      </div>
    ));
  }
};

export default CartBody;
