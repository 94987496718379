import React from "react";
import { Stack, Button, Row, Col } from "react-bootstrap";
import ModalInterface from "../../common/ModalInterface";
import { v4 as uuidv4 } from "uuid";
import {
  AddToCart,
  DecreaseQuantity,
  DeleteFromCart,
  IncreaseQuantity,
  ClearTempCart,
  GetCartLength
} from "../../store/actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { resetMenu } from "../../store/actions/menuAction";
import "./index.css";
import parse from "html-react-parser";
import { resetBestSellerMenu } from "../../store/actions/bestsellerAction";
import { resetSpecialMenu } from "../../store/actions/specialAction";
import CartBody from "./cart/CartBody";
import { checkMandatorySelection, getCalculatedPricing } from "./cart/cartUtilities";

export const Cart = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalMsg, setModalMsg] = useState({});
  const [ShowBottomChevron, SetShowBottomChevron] = useState(false);
  const [showTopChevron, SetShowTopChevron] = useState(false);
  const description = useSelector((state) => state?.menu?.description);
  const descriptionLoader = useSelector((state) => state?.menu?.descriptionLoader);
  const cartData = useSelector((state) => state.menu?.cartData);

  const loadingIng = useSelector((state) => state.menu?.ingredientsLoading);

  const modalIngRef = useRef();
  let indexOfGroup = -1;

  const setToTop = (index) => {
    indexOfGroup = 0;
    document.getElementById(`${"IngGroup"}${index}`).scrollIntoView({
      behavior: "smooth"
    });
  };

  const scrollToGroup = (scrollToTop = false) => {
    if (document.getElementById(`${"IngGroup"}${indexOfGroup + 1}`) !== null) {
      indexOfGroup++;
      document.getElementById(`${"IngGroup"}${indexOfGroup}`).scrollIntoView({
        behavior: "smooth"
      });
    }
    if (document.getElementById(`${"IngGroup"}${indexOfGroup + 1}`) === null) {
      SetShowTopChevron(true);
      SetShowBottomChevron(false);
    } else {
      SetShowBottomChevron(true);
      SetShowTopChevron(false);
    }

    if (scrollToTop) {
      setToTop(0);
    }
  };
  const writeDescription = () => {
    if (typeof description?.data?.descriptions === "string") {
      return description?.data?.descriptions;
    }
    let descEN = description?.data?.descriptions?.find(
      (dsc) => dsc?.localeCode === "en" && dsc?.description !== ""
    );
    let descDE = description?.data?.descriptions?.find(
      (dsc) => dsc?.localeCode === "de" && dsc?.description !== ""
    );

    if (descDE) {
      return descDE?.description;
    } else {
      return descEN?.description;
    }
  };

  const dispatch = useDispatch();
  const orders = useSelector((state) => state.order);

  const findCartitem = (id, index, type) => {
    if (index === true) {
      const indexOfItem = orders.tempCart.findIndex((item) => item?.id === id);

      if (indexOfItem <= -1 && type === "increment") {
        dispatch(AddToCart({ tempCart: props?.cartData, temporary: true }));
        dispatch(GetCartLength());
      } else if (indexOfItem >= 0 && type === "increment") {
        dispatch(IncreaseQuantity({ temp: indexOfItem, temporary: true }));
      } else if (type === "decrement" && indexOfItem >= 0) {
        dispatch(DecreaseQuantity({ temp: indexOfItem, temporary: true }));
      }
    } else {
      let quantity = orders?.tempCart?.filter((item) => item?.id === id);
      if (quantity?.length <= 0) {
        quantity = [{ quantity: "1" }];
        return quantity;
      } else {
        return quantity;
      }
    }
  };

  const checkInTempCart = async () => {
    let checkSelection = await checkMandatorySelection(props.cartData);
    setModalMsg(checkSelection);
    let condition = checkSelection?.checkedItems;
    if (props.cartData?.ingredients?.length === 0) {
      condition = true;
    }

    if (condition) {
      dispatch(
        AddToCart({
          item: orders?.tempCart[0],
          latestIng: cartData?.ingredients
        })
      );

      dispatch(resetMenu());
      dispatch(resetSpecialMenu());
      dispatch(resetBestSellerMenu());
      dispatch(
        DeleteFromCart({
          tempCart: props?.cartData,
          temporary: true
        })
      );
      dispatch(ClearTempCart());
      props.setShowCart(false);
      props.setShowSuggestions(true);
    } else {
      setShowModal(true);
    }
  };

  const closeCart = async () => {
    dispatch(resetMenu());
    dispatch(resetSpecialMenu());
    dispatch(resetBestSellerMenu());
    dispatch(
      DeleteFromCart({
        tempCart: props?.cartData,
        temporary: true
      })
    );

    dispatch(ClearTempCart());
    props.setShowCart(false);
  };
  return (
    <>
      <ModalInterface
        classname="cart-modal"
        display={props.showCart}
        size="lg"
        style={{ position: "relative" }}
        handleClose={async () => {
          closeCart();
        }}
        bodyStyles={{ height: "100vh", overflowY: "scroll" }}
        isCart={true}
        title={
          <>
            {" "}
            <Stack direction="horizontal" className="justify-content-between ">
              <div
                className="dish-cross-modal d-flex align-items-center"
                role="button"
                style={{
                  backgroundColor: props?.page_theme?.primary_modal_cross_btn_bg_color || "#e74c3c"
                }}>
                <i
                  className="fa fa-close fa-stack-1x fa-inverse text-white"
                  onClick={async () => {
                    closeCart();
                  }}></i>
              </div>
              <Row className="w-100 p-2">
                <Col sm="6" md="6" lg="6" xs="6">
                  <div className="mdlTopWrp">
                    <div className="mdltitle" id="mdltitle">
                      {props?.cartData?.name}
                    </div>
                    <div className="mdlPrice" id="mdlPrice">
                      €{" "}
                      <span
                        style={{ pointerEvents: "none" }}
                        onClick={() => () => {
                          return false;
                        }}>
                        {getCalculatedPricing(orders, props.cartData)}
                      </span>
                    </div>
                  </div>{" "}
                </Col>
                <Col sm="6" md="6" lg="6" xs="6">
                  <div className="d-flex justify-content-end align-items-center pt-3 ">
                    <img
                      src="/img/info.png"
                      alt="ing_info"
                      role="button"
                      className={
                        props?.cartData?.allergens?.length > 0
                          ? "align-top mt-2 visible "
                          : "align-top mt-2 invisible"
                      }
                      height={22}
                      onClick={async () => {
                        props.setInfoAllergens(true);
                        props.setAllergenData(props?.cartData?.allergens);
                      }}
                    />
                    {props?.cartData?.isBestSeller === true ? (
                      <img
                        src="/img/hearticon.png"
                        alt="hearticon"
                        className="mt-2 mx-2"
                        height={22}
                        onClick={() => {
                          props.setInfoAllergens(true);
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {props?.cartData?.categories?.map((cat) => {
                      return (
                        <React.Fragment key={uuidv4()}>
                          {cat?.icon !== null ? (
                            <img
                              src={cat?.icon}
                              className="mt-2 mx-1 "
                              height={22}
                              alt="cover"
                              style={{
                                cursor: "grab"
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </Stack>
          </>
        }
        content={
          <div>
            {/* <hr /> */}

            <div className="pt-4 ">
              <div className="text-center">
                <img
                  src={props?.cartData?.image || "./img/PlatenKnife.png"}
                  height={190}
                  style={{ width: "100%", objectFit: "cover" }}
                  alt="Cart Item"
                  className="img-responsive text-center"
                />
              </div>
              <p className="text-center mt-5" style={{ fontSize: "22px" }}>
                {descriptionLoader && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                )}
                {(writeDescription() && parse(writeDescription())) || ``}
              </p>
              <div>
                <div className="border-0 px-0">
                  {props?.cartData?.ingredients?.length > 0 && (
                    <div className="w-100 d-flex justify-content-center align-items-center">
                      {/* <Button
                        className={`"text-center justify-content-center fs-${props?.page_theme?.font_size_personalize_btn} font-${props?.page_theme?.font_family_personalize_btn}"`}
                        size="md"
                        role="button"
                        style={{
                          border: "none",
                          borderRadius: "20px",
                          color: props?.page_theme?.primary_personalize_btn_text_color,
                          backgroundColor: props?.page_theme?.primary_personalize_btn_bgcolor
                        }}
                        disabled={loadingIng}
                        onClick={() => {
                          setToTop(0);
                          SetShowBottomChevron(true);
                        }}>
                        PERSONALISIEREN
                      </Button> */}
                    </div>
                  )}
                  {/* {loadingIng && (
                    <div className="d-block text-center pt-4">
                      <span
                        className="spinner-border spinner-border-md text-danger"
                        role="status"
                        aria-hidden="true"></span>
                    </div>
                  )} */}
                  {!loadingIng && (
                    <div className="border-0 px-0" ref={modalIngRef}>
                      <CartBody page_theme={props?.page_theme} />
                    </div>
                  )}
                </div>
                <div id="scrollBottm"></div>
              </div>
            </div>
            {!loadingIng && props?.cartData?.ingredients?.length > 0 ? (
              <div
                className={
                  ShowBottomChevron
                    ? " d-flex align-items-center justify-content-center scrollbtn"
                    : "d-none"
                }>
                <div
                  onClick={() => scrollToGroup()}
                  role="button"
                  className="bg-danger text-center "
                  style={{
                    width: "50px",
                    position: "relative",
                    borderRadius: "10px",
                    height: "30px"
                  }}>
                  <i
                    className={`fas ${"fa-chevron-down"}`}
                    style={{ fontSize: "28px", color: "white" }}>
                    {" "}
                  </i>
                </div>
              </div>
            ) : null}
            {!loadingIng && props?.cartData?.ingredients?.length > 0 ? (
              <div
                className={
                  showTopChevron
                    ? " d-flex align-items-center justify-content-center scrollbtn"
                    : "d-none"
                }>
                <div
                  onClick={() => scrollToGroup(true)}
                  role="button"
                  className="bg-danger text-center "
                  style={{
                    width: "50px",
                    position: "relative",
                    borderRadius: "10px",
                    height: "30px"
                  }}>
                  <i
                    className={`fas ${"fa-chevron-up"}`}
                    style={{ fontSize: "28px", color: "white" }}>
                    {" "}
                  </i>
                </div>
              </div>
            ) : null}
          </div>
        }
        cartFooter={
          <>
            <Stack
              direction="horizontal"
              className="justify-content-between"
              style={{
                backgroundColor: "#F7F3EE",
                padding: "1rem"
              }}>
              <div className="align-center">
                <span
                  className="btn"
                  onClick={() => {
                    findCartitem(Number(props?.cartData?.id), true, "decrement");
                  }}>
                  <i className="fas fa-minus"></i>
                </span>
                <h4 className="d-inline text-dark">
                  {findCartitem(Number(props?.cartData?.id), false)?.map((data) => {
                    return Number(data.quantity);
                  })}
                </h4>
                <span
                  className="btn  "
                  onClick={() => {
                    findCartitem(Number(props?.cartData?.id), true, "increment");
                  }}>
                  <i className="fa-solid fa-plus"></i>
                </span>
              </div>
              <div>
                {!loadingIng ? (
                  <>
                    <Button
                      className={`fs-${props?.page_theme?.font_size_order_btn} font-${props?.page_theme?.font_family_order_btn}`}
                      // variant="danger"
                      size="md"
                      style={{
                        borderRadius: "20px",
                        border: "none",
                        color: props?.page_theme?.primary_order_btn_text_color,
                        backgroundColor: props?.page_theme?.primary_personalize_btn_bgcolor
                      }}
                      disabled={loadingIng}
                      onClick={() => {
                        checkInTempCart();
                      }}>
                      IN DEN WARENKORB
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Stack>
          </>
        }
        className={"text-dark pt-2"}
        closeEvent={async () => {
          closeCart();
        }}
      />
      <ModalInterface
        display={showModal}
        size="lg"
        center="true"
        handleClose={async () => {
          setShowModal(false);
        }}
        title={
          <>
            <div className="d-flex justify-content-end p-2">
              <span
                className="bg-danger rounded-circle p-2 d-flex align-items-center justify-content-center text-white"
                style={{ height: "25px", width: "25px" }}>
                {" "}
                <i
                  role="button"
                  className="fa fa-close fs-4"
                  onClick={async () => {
                    setShowModal(false);
                  }}></i>
              </span>
            </div>
          </>
        }
        content={
          <>
            <h4 className="ps-5"> Bitte beantworten sie die Frage</h4>
            {modalMsg?.groups?.map((group) => {
              return <div className="ps-5 fs-5" key={uuidv4()}>{group}</div>;
            })}
          </>
        }
      />
    </>
  );
};
