import { GET_SPECIAL, SPECIALS_FAIL } from "../types";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
// import axios from "axios";

var specialRepository = RepositoryFactory.get("special");
export const getspecials = (payload) => async (dispatch) => {
  try {
    const res = await specialRepository.getSpecial(payload);
    dispatch({
      type: GET_SPECIAL,
      payload: res.data?.data
    });
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: SPECIALS_FAIL,
      payload: error
    });
  }
};

export const resetSpecialMenu = () => async (dispatch) => {
  dispatch({
    type: "RESET_SPECIAL"
  });
};
