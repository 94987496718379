import { Form, Stack } from "react-bootstrap";
const DivideModal = (props) => {
  const total = props?.isSelectedBill
    ? props?.selectedBillAmount.toFixed(2)
    : props.paidAmount.toFixed(2) > 0.0
    ? (props?.fullAmount.toFixed(2) - props?.paidAmount.toFixed(2)).toFixed(2)
    : // .toFixed(2)
      props?.bill.toFixed(2);

  return (
    <div className=" d-flex flex-column justify-content-center " style={{ marginTop: "-25px" }}>
      <div className="w-100 dividePara text-black">
        {/* <h1 className="" > */}
        <div className="" style={{ width: "85%" }}>
          {props.divisionAmount > 0
            ? "Someone from your table has already initiated payment"
            : "DURCH WIE VIELE PERSON TEILEN?"}
        </div>
      </div>

      <div className=" d-flex justify-content-center flex-column w-100 align-items-center ">
        {/* <p>DEIN RECHNUNGSBETRAG</p> */}

        <div
          className=" billTotal d-flex justify-content-center pt-3 pb-3 flex-column"
          style={{ width: "85%" }}
        >
          <div className="text-center fw-bold" style={{ fontSize: "20px" }}>
            RECHNUNGSBETRAG
          </div>
          <div className="d-flex justify-content-center">
            <span className="d-fle d-flex justify-content-center align-items-center">
              <img src="https://app1.va-piano.ch/img/bill.png" width={45} height={45} alt="bill" />{" "}
            </span>
            <h4 className="totalPr ms-2">
              <span>
                {
                  // props.divisionAmount > 0
                  //   ? parseFloat(props.divisionAmount).toFixed(2)
                  //   : // .toFixed(2)
                  //     parseFloat(props.bill).toFixed(2)
                  // .toFixed(2)
                  parseFloat(total).toFixed(2)
                }{" "}
                €
              </span>
            </h4>
          </div>
        </div>
      </div>
      {/* <h4 className="divideLabel">PERSONENZAHL</h4> */}

      {/* {props.} */}
      <Stack direction="horizontal" className="justify-content-center divInp w-100 mt-4">
        <div className="d-flex  justify-content-center " style={{ width: "85%" }}>
          <Form.Control
            type="number"
            onChange={(e) => {
              props?.setCount(e.target.value);
              props?.setFinalDivideCount(e.target.value);
            }}
            disabled={props.divisionAmount > 0 ? true : false}
            placeholder="Enter only digits"
            required
            className="divInpBox"
          />
          <button
            // variant="info"
            onClick={() => {
              props.setShowDivide(false);
              props.setShowPay(true);
              props?.updateBill();
            }}
          >
            {" "}
            <i className="fa fa-check" style={{ color: "white", fontSize: "25px" }}></i>
          </button>
        </div>
      </Stack>
    </div>
  );
};

export default DivideModal;
