import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { canvasGrid } from "../../store/actions/canvasActions";
import { v4 as uuidv4 } from "uuid";

export const WallOfFame = ({ page_theme }) => {
  const data = useSelector((state) => state.canvas.canvasGrid);
  const businessID = useSelector((state) => state.table.orderInfo.businessLocationId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(canvasGrid(businessID));
  }, []);

  return (
    <div style={{ marginBottom: "100px", paddingBottom: "100px" }}>
      <h1
        className={`fs-${page_theme?.font_size_wall_of_fame_title} font-${page_theme?.font_family_primary_wall_of_fame_title}`}
        style={{
          // fontSize: "35px",
          fontWeight: "600",
          color: page_theme?.primary_wall_of_fame_title_text_color
        }}>
        Unsere WALL OF FAME
      </h1>
      <h4
        style={{
          // fontSize: "35px",
          fontWeight: "600"
        }}>
        NACH PRÜFUNG DES EINTRAGES WIRD DIES AUF UNSER WALL OF FAME VERÖFFENTLICH
      </h4>
      <div className="w-100" style={{ height: "5px", background: "rgb(255, 186, 36)" }}></div>
      <Row className="mt-5">
        {/* <div className="d-flex  justify-content-between mt-5 flex-wrap"> */}
        {data.data &&
          data.data.map((img) => {
            return (
              <Col md="6" sm="6" lg="6" xs="6" className="mt-3" key={uuidv4()}>
                <div className="wallofFameImg">
                  <img
                    style={{ objectFit: "contain", background: "white" }}
                    src={img.file}
                    alt="canvas"
                    className="img-responsive"
                  />
                </div>
              </Col>
            );
          })}
        {/* </div> */}
      </Row>
    </div>
  );
};
